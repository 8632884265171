
// -----------edit profile start--------//
.services-text{
font-size: 18px !important;
color: color('secondary') !important;
text-transform: capitalize;
margin-left: 10px !important;
font-weight: 400 !important;
}
.left-side-menu-show{
    a, span{
        font-size: 16px;
        padding-left: 0;
        &:hover, &:active{
            color: color('primary');  
        }
    }
    span{
        color: color('tertiary');  
    }
    .listing-text.active span{
        color: color('primary');  
    }
}
// ----------payment----------------//
.label-bottom-space{
    margin-bottom: 8px !important;
}






/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .left-side-menu-show{
        // display: flex;
        // overflow-x: scroll;
        // white-space: nowrap;
        a{
            // padding: 0;
        }
        span{
        //   padding-bottom: 10px;
        }
      }
      .edit-profile-menu{
        li{
            width: unset;
            }
        }
        .edit-profile-menu li:not(:first-child){
            padding: 0 25px ;
        }
        .small-heading {
            font-size: 18px;
        }
        .service-heading h2 {
            font-size: 20px !important;
        }
        // --------------payment start---------//
        .label-bottom-space{
            text-align: start !important;
        }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .left-side-menu-show{
        // display: flex;
        // overflow-x: scroll;
        // white-space: nowrap;
        a{
            // padding: 0;
        }
        span{
            // padding-bottom: 10px;
          }
      }
      .edit-profile-menu{
        li{
            width: unset;
            }
        }
        .edit-profile-menu li:not(:first-child){
            padding: 0 30px ;
        }
}

// Medium devices (tablets, 768px and up) /
@include media-breakpoint-between(md, lg) {
    .left-side-menu-show{
        // display: flex;
        // overflow-x: scroll;
        // white-space: nowrap;
        a{
            // padding: 0;
        }
        span{
           padding-right: 10px;
          }
      }
      .edit-profile-menu{
        li{
            width: unset;
            }
        }
        .edit-profile-menu li:not(:first-child){
            padding: 0 25px ;
        }
      }
// Large devices (desktops, 992px and up) /
@include media-breakpoint-between(lg, xl) {
 
}

// Extra large devices (large desktops, 1200px and up) /
@include media-breakpoint-between(xl, xxl) {}


.info-right-card{
    border: 1px solid #DDDDDD !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    padding: 10px !important;
    padding-top: 0px !important;
}