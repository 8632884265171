// -------favorite page start ----------------//
.inner-heading {
    font-size: 18px !important;
    color: color('secondary');
    margin-bottom: 10px !important;
}

.listing-visible li:not(:nth-child(-n+2)) {
    display: inline-flex;
}

.inner-value-heading {
    font-size: 14px !important;
    color: color('secondary');
}

.favorite-link {
    text-decoration: underline !important;
    color: color('tertiary') !important;
    font-size: 14px !important;
}

.sub-list {
    display: flex;
}

.protect-check {
    background: color('primary');
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.favorite-bg {
    display: flex;
    gap: 25px;
    width: 100%;
    align-items: center;

    .favorite-img {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background: color('primary');
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}

.favorite-total-box {
    display: flex;
    justify-content: space-between;
    background: color('light-fourth');
    padding: 30px;
    border-radius: 30px;
    align-items: center;
}

.favorite-heading {
    width: 100%;
}

.listing-visible {
    gap: 30px;

    li {
        width: unset;
        padding: 0;
    }
}

// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .favorite-bg {
        align-items: start;
        flex-direction: column;
    }

    .listing-visible {
        gap: 15px;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .listing-visible {
        gap: 15px;
    }
}

// Medium devices (tablets, 768px and up) /
@include media-breakpoint-between(md, lg) {
    .listing-visible {
        gap: 15px;
    }
}

// Large devices (desktops, 992px and up) /
@include media-breakpoint-between(lg, xl) {
    .listing-visible {
        gap: 15px;
    }
}

// Extra large devices (large desktops, 1200px and up) /
@include media-breakpoint-between(xl, xxl) {}