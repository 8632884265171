// ======================= Hero banner Styles Start =======================
.hero-section {
    padding-top: 40px;
    padding-bottom: 80px;
}

.hero-banner {
    .slider-caption {
        position: absolute;
        color: color('light');
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        @include gradient-background('secondary-gradient');

        .slider-caption-block {
            padding: 0 60px;
            text-align: center;

            h1 {
                font-weight: 400;
                font-size: 48px;
                line-height: 60px;
                margin-bottom: 0;
                // margin-bottom: 75px;
                width: 630px;
            }

            .rental-search-form {
                display: none;
            }
        }
    }

    .hero-image-item {
        position: relative;

        .hero-image {
            border-radius: 32px;
            width: 100%;
            height: 350px;
            object-fit: cover;
        }

    }
}

.modal-filter-btn {
    justify-content: end !important;

    .filter-button {
        width: 130px;
        margin-top: 30px;

        span {
            display: inline-block;
        }

        img {
            width: 23px;
        }
    }
}

.rental-search-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .input-style:not(.select-style) {
        width: 215px;
    }

    .beach-gear-search {
        width: 245px !important;
    }

}

.rental-date-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color('light');
    border-radius: 32px;
    // width: 420px;  -------- before filter price changes
    // width: 360px;

    .end-date {
        border-left: 1px solid color('light-fifth');
        border-radius: 0 32px 32px 0;
    }
}

.input-style {
    background-color: color('light');
    color: color('secondary');
    font-size: 16px;
    border-radius: 50px;

    .MuiSelect-select {
        border: 0;
        height: 60px;
        padding: 0 25px 0 0;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: color('secondary');
            opacity: 1;
            /* Firefox */
        }

        &::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: color('secondary');
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: color('secondary');
        }
    }

    input {
        border: 0;
        height: 48px;
        // height: 60px;
        // padding: 0 25px 0 0;  -------- before filter price changes
        padding: 0 8px 0 0;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: color('secondary');
            opacity: 1;
            /* Firefox */
        }

        &::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: color('secondary');
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: color('secondary');
        }
    }

    .input-image {
        flex-shrink: 0;
        margin-right: 0;

        img {
            width: 25px;
        }
    }
}

.outlined-input {
    border: 1px solid color('light-sixth') !important;
}

.filter-button {
    background-color: color('primary') !important;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    min-width: unset !important;
    padding: 13px !important;
    text-align: center;
    color: white;

    span {
        font-size: 14px;
        text-transform: capitalize;
        margin-left: 10px;
        display: none;
    }

    img {
        filter: brightness(0) invert(1);
    }

    &:hover {
        color: color('light');
    }
}

.select-style {
    width: 170px;

    &>div {
        height: 48px;

        & #demo-customized-select {
            background-color: transparent !important;
            padding: 0 !important;
            border-radius: 0 !important;
            min-height: unset !important;
            min-width: unset !important;
            text-align: start;
            font-size: 16px;
        }
    }

    svg {
        right: 10px;
        color: color('tertiary');
    }
}

// ======================= Hero banner Styles end =======================

// ======================= Popular beach gear Styles Start =======================
.main-heading-block {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main-heading {
        font-weight: 400;
        font-size: 32px;
        color: color('secondary');
        line-height: 44px;
        margin-top: 0;
    }

    .view-all-btn {
        flex-shrink: 0;
        margin-left: 15px;
    }
}
a.view-all-btn:hover{
    color: black;
}
.view-all-btn {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: black;

    img {
        margin-left: 12px;
    }
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 24px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
}

.vertical-card {
    position: relative;
    z-index: 1;
    transform-style: preserve-3d;
    transition: transform 5s !important;
    transition: width 500ms ease-in-out !important;
    overflow: unset !important;

    .card-media-item {
        padding: 10px 8px;
    }

    .card-image {
        height: 323px;
    }

    &:hover {
        z-index: 2;

        .feature-listing {
            li {
                display: flex;
            }
        }

        .card-media-item {
            position: absolute;
            box-shadow: 0px 5px 31px -1px rgba(0, 0, 0, 0.15);
            transition: 100ms ease-out;
        }
    }
}

.card-media-block {
    position: relative;

    .card-slider {
        position: relative;
    }
}

.card-info {
    .card-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin: 20px 0 15px 0;
        color: color('secondary');
    }

    &:hover {
        .stepper-dots {
            button {
                display: inline-flex;
            }
        }
    }
}

.feature-listing {
    li {
        padding: 0 0 15px 0;
        align-items: flex-start;

        &:not(:nth-child(-n+2)) {
            display: none;
        }

        .feature-listing-icon {
            min-width: 16px !important;
            margin-right: 10px;
            margin-top: 3px;
        }

        .feature-list-name {
            margin: 0;

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: color('tertiary');
            }
        }
    }
}

.pricing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: color('secondary');
    font-family: $primaryFont;
    margin-bottom: 0;

    span {
        color: color('tertiary');
    }
}

.featuring-block {
    position: absolute !important;
    top: 15px;
    left: 0;
    width: 80%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    .featuring-badge {
        padding: 5px;
        border-radius: 0px 8px 8px 0px;
        background-color: color('primary');
        color: color('light');
        margin-bottom: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        max-width: 140px;

        img {
            filter: brightness(0) invert(1);
            margin-left: 5px;
            width: 20px;
        }
    }
}

.featuring-action-block {
    position: absolute;
    top: 16px;
    right: 10px;
}

.featuring-edit-block {
    position: absolute;
    top: 16px;
    left: 2px;
}

// .vertical-card{
//     .stepper-dots{
//         button{
//             display: none !important;
//         }
//     }
// }
// ======================= Popular beach gear Styles End =======================

// ======================= card defaults style changes start ======================= 
.card-info {
    button:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: 0;
        }
    }

    .stepper-dots {
        z-index: 1;
        padding: 0;
        background: transparent;

        .MuiMobileStepper-dots {
            position: absolute;
            bottom: 10px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            transform: translateX(-50%) !important;

            .MuiMobileStepper-dot {
                width: 4px;
                height: 4px;
                background-color: color('light');

                &.MuiMobileStepper-dotActive {
                    width: 10px;
                    border-radius: 12px;
                }
            }
        }

        button {
            position: absolute;
            width: 28px;
            height: 28px;
            padding: 0;
            min-width: unset;
            background-color: color('light-opacity');
            border-radius: 100%;
            transform: translateY(-50%) !important;
            margin: 0 16px;
            display: none;

            &.prev-btn {
                top: 50%;
                right: 0;
            }

            &.next-btn {
                top: 50%;
                left: 0;
            }
        }

    }
}

// =======================  card defaults style changes end ======================= 

// ======================= Popular categories Styles Start =======================
.category-card {

    // margin-right: 20px;
    .card-image {
        height: 190px;
        width: 190px;
    }

    .card-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        color: color('secondary');
        width: 190px;
    }
}

.popular-category {
    .slick-arrow {
        margin-top: -1px;

        &:before {
            margin-bottom: 50px;
        }
    }
}

// ======================= Popular categories Styles end =======================

// ======================= All beaches nearby Styles Start =======================
.info-square-card {
    .card-image {
        height: 323px;
    }
}

.feature-listing-inline {
    li {
        display: inline-flex !important;
        width: unset;
        margin-right: 30px;
        padding: 0 0 10px 0;
    }
}

// ======================= All beaches nearby Styles end =======================

// ======================= previously viewed Styles start =======================
.previously-viewed {
    .info-square-card {
        margin-right: 40px;
    }

    .slick-arrow {
        &:before {
            margin-bottom: 80px;
        }
    }
}

// ======================= previously viewed Styles end =======================

// ======================= previously rented Styles Start =======================
.previously-rented {

    // margin-right: -40px;
    .vertical-card {
        margin-right: 40px;

        .card-media-item {
            padding: 0;
        }

        &:hover {
            .card-media-item {
                position: unset;
                box-shadow: none;
            }

        }
    }

    .feature-listing {
        li:not(:nth-child(-n+2)) {
            display: flex;
        }
    }

    .slick-arrow {
        &:before {
            margin-bottom: 120px;
        }
    }
}

// ======================= previously rented Styles end =======================

.slick-arrow {
    z-index: 1;

    &.slick-next,
    &.slick-prev {
        height: 100% !important;
        display: flex !important;
        align-items: center;
        transform: translate(0, -50%);

        &::before,
        &::before {
            content: '';
            opacity: 1 !important;
            width: 36px;
            height: 36px;
        }

        &.slick-disabled {
            display: none !important;
            background: unset !important;

            &:before {
                background-image: unset !important;
            }
        }
    }
}

.slick-next {
    right: 0 !important;
    background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 99%) 20%, rgba(255, 255, 255, 0) 100%) !important;
    width: 60px !important;
    justify-content: flex-end;

    &::before {
        background-image: url('../../images/right-arrow.svg') !important;
    }
}

.slick-prev {
    left: 0 !important;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 99%) 20%, rgba(255, 255, 255, 0) 100%) !important;
    width: 60px !important;
    justify-content: flex-start;

    &::before {
        background-image: url('../../images/left-arrow.svg') !important;
    }
}

.rental-block {
    display: inline-flex;
    gap: 8px;
}

.grid-5 {

    .vertical-card:hover:nth-child(5n+1):nth-last-child(-n+5),
    .vertical-card:nth-child(5n+1):nth-last-child(-n+5)~.vertical-card:hover {
        // height: 490px;
    }
}

.wishlisted-product {
    display: none !important;
}

.mobile-view-all-btn {
    margin-top: 20px !important;
    display: none;
}

// ============================= hero section filters
.filter-popper {
    z-index: 4;
    max-width: 500px;
    min-width: 400px;

    .filter-popper-paper {
        margin-top: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 20px 0px rgba(9, 6, 15, 0.1);
    }

    .filter-scrollbar {
        height: 450px;
    }

    ul {
        padding: 25px 30px;

        li {
            padding: 0;

            &:not(:last-child) {
                padding-bottom: 20px;
            }
        }
    }
}

.filter-menu-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px;
    border-top: 1px solid color('light-tertiary-opacity');

    .save-btn {
        margin-left: auto;
    }
}

.save-btn {
    height: 50px;
    width: 205px;
    text-transform: capitalize !important;
}


.beachgear-value-input {
    display: flex;
    align-items: center;
    gap: 15px;

    label {
        color: color('tertiary');
        width: 230px;
        font-size: 16px;
    }

    .quantity-input {
        flex-shrink: 0;
    }
}

.value-button {
    display: inline-block;
    margin: 0px;
    text-align: center;
    vertical-align: middle;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
}

input {
    &#number {
        color: color('secondary');
        text-align: center;
        border: none;
        width: 35px;
        height: 24px;
        margin: 0;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}

.location-value-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .location-value {
        display: flex;
        align-items: center;
    }

    img {
        height: 72px;
        width: 115px;
        margin-right: 20px;
        flex-shrink: 0;
    }

    label {
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        color: color('secondary');
        width: 155px;
        margin-right: 15px;
    }
}

.customized-select {
    em {
        color: color('secondary');
    }
}

.modal-sub-title {
    font-size: 18px !important;
}

.input-field {
    .MuiFilledInput-root {
        background: color('light');

        &:hover,
        &:focus {
            background: color('light');
        }

        // &::before, &::after{
        //     border-bottom: 0 !important;
        //     &:hover{
        //         border-bottom: 0 !important;
        //     }
        // }
    }

    .MuiInputBase-root {
        border: 1px solid color('light-tertiary');
        border-radius: 18px;
    }

    label {
        font-size: 14px;
        color: color('tertiary');
        font-family: $primaryFont;
        text-transform: capitalize;

        &.Mui-focused {
            color: color('tertiary') !important;
        }
    }

    input {
        font-size: 18px;
        font-family: $primaryFont;
        color: color('secondary');

        &:autofill {
            border-top-left-radius: 0 !important;
            background-color: transparent !important;
        }

        &:focus {
            background-color: transparent !important;

        }
    }
}

.category-card {
    margin-right: 40px;
}

.tool-tip-box {
    color: color('secondary') !important;
    min-width: unset !important;
    background: color('light-tertiary') !important;
    height: 25px;
    width: 25px;
    border-radius: 50% !important;
    margin-left: 5px !important;

    &:hover {
        background: color('light-tertiary');
    }
}

.modal-sub-title {
    margin-bottom: 15px !important;
}

.search_filter {
    padding: 25px 30px;

    .input-style {
        margin-bottom: 25px;
    }
}

.calender-field {
    background: url(../../images/date-picker.svg) no-repeat 25px center;
    background-size: 25px;

    .MuiSvgIcon-root {
        display: none;
    }
}

// --sign up popup-----//
.social-media-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    button {
        padding: 15px 0;
        min-width: 210px;
    }
}


.no-hover.vertical-card:hover .card-media-item {
    position: unset;
}
.edit-profile-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.link-update{
    margin-top: 0;
}
.operation-padding{
    padding: 0 !important;
}
.language-selection{
    flex-wrap: wrap !important;
    gap: 5px;
}
.opening-date > div{
width:50%;
}
.location-padding .Mui-focused .MuiOutlinedInput-notchedOutline{
 border-color: #c4c4c4 !important;
 border-width: 1px !important;
 
}
.location-padding div:hover .MuiOutlinedInput-notchedOutline{
    border-color:#c4c4c4 !important;
  
   }

.button:hover{
background-color: none !important;
}
.inner-points{
    font-size: 17px;
}
.MuiIconButton-root:hover{
    background-color: none !important;
   
}
.MuiIconButton-root{
    .MuiSvgIcon-fontSizeMedium{
        margin-bottom: 0 !important;
    }
}
.time-field .input-style{
    font-size: 15px;
}

/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .slider-caption {
        .rental-search-form {
            display: block;
            text-align: center;

            &>.input-style {
                &:first-child {
                    border-radius: 12px 12px 0 0;
                }

                &:nth-last-child(2) {
                    border-radius: 0 0 12px 12px;
                }
            }
        }

        .rental-date-block {
            width: unset;
            display: block;
            background-color: transparent;

            .end-date {
                border-radius: 0 0 12px 12px;
                border-left: 0;
            }
        }

        .input-style {
            font-size: 14px;
            background-color: color('light');
            border-radius: 0;
            width: 100%;

            &:not(.input-style.end-date) {
                border-bottom: 1px solid color('light-sixth');
            }

            input {
                height: 50px;
                padding: 0 15px 0 0;
            }

            .input-image {
                padding-left: 15px;
                width: 45px;
            }
        }
    }

    .hero-section {
        padding-bottom: 40px;
        padding-top: 30px;
    }

    .hero-banner {
        .slider-caption {
            .slider-caption-block {
                padding: 0 25px;

                h1 {
                    font-size: 26px;
                    line-height: 33px;
                    margin-bottom: 30px;
                    width: unset;
                }

                .rental-search-form {
                    display: block;
                }
            }
        }

        .hero-image-item {
            .hero-image {
                height: 490px;
            }
        }
    }

    .rental-search-form {
        display: block;
        text-align: center;

        &>.input-style {
            &:not(.select-style) {
                width: 100%;
            }
        }
    }

    .rental-date-block {
        width: unset;
        display: block;
        background-color: transparent;

        .end-date {
            border-radius: 0 0 12px 12px;
            border-left: 0;
        }
    }

    .input-style {
        font-size: 14px;
        background-color: color('light');
        // border-radius: 0;
        width: 100% !important;

        &:not(.input-style.end-date) {
            border-bottom: 1px solid color('light-sixth');
        }

        input {
            height: 50px;
            padding: 0 15px 0 0;
            font-size: 14px;
        }

        .input-image {
            padding-left: 15px;
            width: 45px;
        }
    }

    .filter-button {
        width: 100%;
        height: 50px;
        margin-top: 15px !important;
        border-radius: 18px !important;

        span {
            display: inline-block;
        }

        img {
            width: 20px;
        }
    }

    .rental-block {
        display: block;
    }

    .main-heading-block {
        display: block;
        margin-bottom: 20px;

        .main-heading {
            font-size: 23px;
            line-height: 25px;
        }

        .view-all-btn {
            display: none;
            margin-left: 0;
        }
    }

    .grid-5 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .grid-3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .vertical-card {
        .card-media-item {
            padding: 0;
            width:100% !important;
        }

        &:hover {
            .card-media-item {
                all: unset;
            }
        }
    }

    .card-info {
        .card-title {
            margin: 20px 0 10px 0;
        }

        .stepper-dots {
            button {
                display: inline-flex;
            }
        }
    }

    .feature-listing {
        li {
            &:not(:nth-child(-n+2)) {
                display: flex;
            }
        }
    }

    .category-card {
        .card-title {
            margin: 12px 0 0 0;
            font-size: 17px;
            width: 150px;
        }

        .card-image {
            height: 150px;
            width: 150px;
        }
    }

    .feature-listing-inline {
        li {
            margin-right: 20px;
        }
    }

    .info-square-card {
        &:not(:last-child) {
            padding-bottom: 15px;
        }
    }

    .previously-rented {
        .vertical-card {
            margin-right: 0;
        }
    }

    .previously-viewed {
        .info-square-card {
            margin-right: 0;
        }
    }

    .grid-5 {

        .vertical-card:hover:nth-child(5n+1):nth-last-child(-n+5),
        .vertical-card:nth-child(5n+1):nth-last-child(-n+5)~.vertical-card:hover {
            height: unset;
        }
    }

    .mobile-view-all-btn {
        // display: block;
    }

    .filter-popper {
        max-width: unset;
        min-width: unset;
        width: 100%;
        padding: 0 16px;
        z-index: 2;

        ul {
            padding: 20px 20px;

            li {
                &:not(:last-child) {
                    padding-bottom: 15px;
                }
            }
        }

        .filter-scrollbar {
            height: 350px;
        }

        .filter-popper-paper {
            margin-top: 8px;
        }
    }

    .beachgear-value-input {
        label {
            width: 150px;
        }
    }

    .filter-menu-footer {
        padding: 15px 15px;
    }

    .save-btn {
        width: 160px;
        height: 45px;
    }

    .location-value-input {
        img {
            height: 60px;
            width: 70px;
        }

        label {
            font-size: 16px;
            width: 160px;
        }
    }

    .slick-arrow {

        &.slick-next,
        &.slick-prev {

            &::before,
            &::before {
                display: none !important;
            }
        }
    }

    .popular-category {
        .slick-next {
            background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255) 34%, rgba(255, 255, 255, 0) 100%) !important;
        }

        .slick-prev {
            background: none !important;
        }
    }

    .slick-next {
        background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255) 34%, rgba(255, 255, 255, 0) 100%) !important;
    }

    .slick-prev {
        background: none !important;
    }

    .select-style {
        .demo-customized-select {
            font-size: 14px;
        }

        &>div {
            height: 50px;
        }
    }

    .customized-select {
        em {
            font-size: 14px;
        }
    }

    .category-card {
        margin: 0 10px;
    }

    .search_filter {
        padding: 20px 20px;

        .input-style {
            margin-bottom: 20px;
        }
    }

    .search-filter-btn {
        padding: 20px 20px 0 20px !important;
    }

    //    -----calender field------//
    .calender-field {
        background-position: 17px center;
        padding-left: 40px !important;
    }

    // --sign up popup-----//
    .social-media-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        button {
            padding: 15px 0;
            width: 100%;
        }
    }

    .modal-filter-btn {
        .filter-button {
            width: 120px;
            display: inline-block;
        }
    }
    .modal-popup-button span{
        margin-left:0;
    }
    .modal-filter-check-box{
        display:flex;
        align-items: start;
    }
    .filter-btn{
        .modal-filter-btn{
            .filter-button{
                width: 100%;
            }
        }
    }
    .day-start{
        display: flex;
        align-items: center;
    }
    .day-start{
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .swith-end{
        display: flex;
        align-items: center;
        justify-content: end;  
    }
    .language-selection{
        flex-wrap: wrap !important;
        gap: 5px;
    }
    .language-selection .css-w4z10b-MuiStack-root>:not(style)+:not(style) {
        margin: 0;
        margin-left: 0px !important;
    }
    .opening-Date{
        padding: 0 !important;
    }
    .opening-date > div {
        width: 100%;
    }
    .edit-profile-padding{
        padding-top: 0 !important;
    }
    .operation-padding .outline-input input {
        padding: 10px !important;
    }
    .mobile-none{
        display: none;
    }
    .time-field .input-style{
        font-size: 15px;
    }
   
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .hero-banner {
        .hero-image-item {
            .hero-image {
                height: 540px;
            }
        }

        .slider-caption {
            .slider-caption-block {
                padding: 0 20px;

                h1 {
                    font-size: 37px;
                    line-height: 46px;
                    margin-bottom: 30px;
                    width: 100%;
                }

                .rental-search-form {
                    display: flex;
                }
            }
        }
    }

    .input-style {
        height: 55px;
        width: 100%;

        .input-image {
            padding-left: 17px;
        }

        input {
            height: 55px;
        }
    }

    .rental-search-form {
        flex-wrap: wrap;
        justify-content: flex-start;

        .input-style:not(.select-style) {
            width: 100%;
        }
    }

    .select-style {
        &>div {
            height: 55px;
        }
    }

    .rental-date-block {
        width: 100%;
    }

    .filter-button {
        width: 100%;
        height: 50px;
        border-radius: 18px !important;

        span {
            display: inline-block;
        }
    }

    .rental-block {
        width: 100%;
    }

    .hero-section {
        padding-bottom: 40px;
    }

    .grid-5 {
        gap: 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .vertical-card:hover:nth-child(5n+1):nth-last-child(-n+5),
        .vertical-card:nth-child(5n+1):nth-last-child(-n+5)~.vertical-card:hover {
            height: unset;
        }
    }

    .grid-3 {
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .vertical-card {
        .card-media-item {
            padding: 0;
        }

        &:hover {
            .card-media-item {
                all: unset;
            }
        }
    }

    .feature-listing {
        li {
            &:not(:nth-child(-n+2)) {
                display: flex;
            }
        }
    }

    .featuring-block {
        .featuring-badge {
            padding: 6px 5px 6px 5px;
            max-width: 120px;
        }
    }

    .featuring-action-block {
        right: 10px;
    }

    .card-info {
        .stepper-dots {
            button {
                margin: 0 6px;
            }
        }
    }

    .main-heading-block {
        margin-bottom: 10px;

        .main-heading {
            font-size: 27px;
        }

        .view-all-btn {
            display: none;
        }
    }

    .vertical-card {
        .card-image {
            height: 273px;
        }
    }

    .category-card {
        .card-image {
            height: 150px;
            width: 150px;
        }

        .card-title {
            width: 150px;
            font-size: 17px;
        }
    }

    .info-square-card {
        .card-image {
            height: 285px;
        }
    }

    .previously-rented {
        .vertical-card {
            margin-right: 25px;
        }
    }

    .previously-viewed {
        .info-square-card {
            margin-right: 25px;
        }
    }

    .mobile-view-all-btn {
        display: block;
    }

    .filter-popper {
        max-width: unset;
        min-width: unset;
        width: 100%;
        padding: 0 36px;
        z-index: 2;

        ul {
            padding: 20px 20px;

            li {
                &:not(:last-child) {
                    padding-bottom: 15px;
                }
            }
        }

        .filter-scrollbar {
            height: 350px;
        }

        .filter-popper-paper {
            margin-top: 8px;
        }
    }

    .beachgear-value-input {
        label {
            width: 350px;
        }
    }

    .filter-menu-footer {
        padding: 20px 20px;
    }

    .save-btn {
        width: 170px;
    }

    .location-value-input {
        img {
            height: 70px;
            width: 100px;
        }

        label {
            font-size: 17px;
            width: 350px;
        }
    }

    .slick-arrow {

        &.slick-next,
        &.slick-prev {

            &::before,
            &::before {
                width: 26px;
                height: 26px;
                background-size: 26px;
            }
        }
    }

    .slick-next {
        background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255) 28%, rgba(255, 255, 255, 0) 100%) !important;
    }

    .slick-prev {
        background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 98%) 0, rgb(255 255 255 / 0%) 100%) !important;
    }

    .slick-disabled {
        background: unset !important;

        &:before {
            background-image: unset !important;
        }
    }

    .select-style {
        .demo-customized-select {
            font-size: 14px;
        }
    }

    .customized-select {
        em {
            font-size: 14px;
        }
    }

    .category-card {
        margin-right: 30px;
    }

    .search_filter {
        padding: 20px 20px;

        .input-style {
            margin-bottom: 20px;
        }
    }

    .search-filter-btn {
        padding: 20px 20px 0 20px !important;
    }

    //    -----calender field------//
    .calender-field {
        background-position: 20px center;
        padding-left: 43px !important;
    }

    // --sign up popup-----//
    .social-media-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        button {
            padding: 15px 0;
            width: 100%;
        }
    }
     // --sign up popup end-----//
    .opening-date > div {
        width: 100%;
    }
    .edit-profile-padding{
        padding-top: 0 !important;
    }
    .swith-end {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .billing-btn{
        margin-top: 45px !important;
    }
    .mobile-none{
        display: none;
    }
    .time-field .input-style{
        font-size: 15px;
    }

}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .hero-banner {
        .hero-image-item {
            .hero-image {
                height: 500px;
            }
        }

        .slider-caption {
            .slider-caption-block {
                h1 {
                    font-size: 40px;
                    line-height: 53px;
                    margin-bottom: 15px;
                    width: 100%;
                }

                .rental-search-form {
                    display: flex;
                }
            }
        }
    }

    .input-style {
        height: 55px;
        width: 100%;

        .input-image {
            padding-left: 17px;
        }

        input {
            height: 55px;
        }
    }

    .rental-search-form {
        flex-wrap: wrap;
        justify-content: flex-start;

        .input-style:not(.select-style) {
            width: 100%;
        }
    }

    .select-style {
        &>div {
            height: 55px;
        }
    }

    .rental-block {
        width: 100%;
    }

    .rental-date-block {
        width: 100%;
    }

    .filter-button {
        width: 100%;
        height: 50px;
        border-radius: 18px !important;

        span {
            display: inline-block;
        }
    }

    .hero-section {
        padding-bottom: 40px;
    }

    .grid-5 {
        gap: 20px 10px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        .vertical-card:hover:nth-child(5n+1):nth-last-child(-n+5),
        .vertical-card:nth-child(5n+1):nth-last-child(-n+5)~.vertical-card:hover {
            height: unset;
        }
    }

    .grid-3 {
        gap: 20px 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .vertical-card {
        .card-media-item {
            padding: 0;
        }

        &:hover {
            .card-media-item {
                all: unset;
            }
        }
    }

    .feature-listing {
        li {
            &:not(:nth-child(-n+2)) {
                display: flex;
            }
        }
    }

    .featuring-block {
        .featuring-badge {
            padding: 6px 10px 6px 5px;
            max-width: 130px;
        }
    }

    .featuring-action-block {
        right: 10px;
    }

    .card-info {
        .stepper-dots {
            button {
                margin: 0 6px;
            }
        }
    }

    .main-heading-block {
        margin-bottom: 10px;

        .main-heading {
            font-size: 27px;
        }
    }

    .vertical-card {
        .card-image {
            height: 273px;
        }
    }

    .category-card {
        .card-image {
            height: 140px;
            width: 140px;
        }

        .card-title {
            font-size: 18px;
            width: 140px;
        }
    }

    .info-square-card {
        .card-image {
            height: 285px;
        }
    }

    .previously-rented {

        // margin-right: -25px;
        .vertical-card {
            margin-right: 25px;
        }
    }

    .previously-viewed {

        // margin-right: -25px;
        .info-square-card {
            margin-right: 25px;
        }
    }

    .filter-popper {
        max-width: unset;
        min-width: unset;
        width: 100%;
        padding: 0 80px;
        z-index: 2;

        ul {
            padding: 20px 20px;

            li {
                &:not(:last-child) {
                    padding-bottom: 15px;
                }
            }
        }

        .filter-scrollbar {
            height: 350px;
        }

        .filter-popper-paper {
            margin-top: 8px;
        }
    }

    .beachgear-value-input {
        label {
            width: 450px;
        }
    }

    .filter-menu-footer {
        padding: 20px 20px;
    }

    .save-btn {
        width: 170px;
    }

    .location-value-input {
        img {
            height: 70px;
            width: 100px;
        }

        label {
            font-size: 17px;
            width: 350px;
        }
    }

    .slick-arrow {

        &.slick-next,
        &.slick-prev {

            &::before,
            &::before {
                width: 26px;
                height: 26px;
                background-size: 26px;
            }

            &.slick-disabled {
                background: unset !important;

                &:before {
                    background-image: unset !important;
                }
            }
        }
    }

    .slick-next {
        background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 97%) 20%, rgb(255 255 255 / 0%) 100%) !important;
    }

    .slick-prev {
        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 97%) 20%, rgb(255 255 255 / 0%) 100%) !important;
    }

    .select-style {
        .demo-customized-select {
            font-size: 15px;
        }
    }

    .customized-select {
        em {
            font-size: 15px;
        }
    }

    .search_filter {
        padding: 20px 20px;

        .input-style {
            margin-bottom: 20px;
        }
    }

    .search-filter-btn {
        padding: 20px 20px 0 20px !important;
    }

    .beach-gear-search {
        width: 205px !important;
    }

    //    -----calender field------//
    .calender-field {
        background-position: 21px center;
        padding-left: 48px !important;
    }

    // --sign up popup-----//
    .social-media-btn {
        display: flex;
        gap: 10px;

        button {
            padding: 15px 0;
            min-width: 180px;
        }
    }
    .opening-date > div {
        width: 100%;
    }
    .edit-profile-padding{
        padding-top: 0 !important;
    }
    .billing-btn{
        margin-top: 45px !important;
    }
    .mobile-none{
        display: none;
    }

}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {

    .hero-banner {
        // .hero-image-item {
        //     .hero-image {
        //         height: 450px;
        //     }
        // }

        .slider-caption {
            .slider-caption-block {
                padding: 0 20px;
            }
        }
    }

    .input-style {
        .input-image {
            padding-left: 12px;
        }

        input {
            padding: 0 10px 0 0;

        }

        .MuiInputAdornment-root {
            img {
                width: 20px;
            }
        }
    }

    .rental-search-form {
        .css-j4g15g-MuiInputBase-root-MuiInput-root {
            font-size: 14px;
        }
    }

    .rental-date-block {
        width: 295px;
    }

    .hero-section {
        padding-bottom: 60px;
    }

    .grid-5 {
        gap: 20px 5px;

        .vertical-card:hover:nth-child(5n+1):nth-last-child(-n+5),
        .vertical-card:nth-child(5n+1):nth-last-child(-n+5)~.vertical-card:hover {
            height: 440px;
        }
    }

    .grid-3 {
        gap: 20px 5px;
    }

    .featuring-block {
        .featuring-badge {
            padding: 6px 5px 6px 5px;
            max-width: 120px;
        }
    }

    .featuring-action-block {
        right: 10px;
    }

    .card-info {
        .stepper-dots {
            button {
                margin: 0 6px;
            }
        }
    }

    .main-heading-block {
        margin-bottom: 25px;
    }

    .vertical-card {
        .card-image {
            height: 273px;
        }
    }

    .category-card {
        .card-image {
            height: 170px;
            width: 170px;
        }

        .card-title {
            width: 170px;
        }
    }

    .info-square-card {
        .card-image {
            height: 285px;
        }
    }

    .previously-rented {
        .vertical-card {
            margin-right: 25px;
        }
    }

    .previously-viewed {
        .info-square-card {
            margin-right: 25px;
        }
    }

    .filter-popper {
        max-width: 340px;
        min-width: 340px;

        ul {
            padding: 20px 20px;

            li {
                &:not(:last-child) {
                    padding-bottom: 15px;
                }
            }
        }

        .filter-scrollbar {
            height: 440px;
        }
    }

    .beachgear-value-input {
        label {
            width: 200px;
        }
    }

    .filter-menu-footer {
        padding: 20px 20px;
    }

    .save-btn {
        width: 170px;
    }

    .location-value-input {
        img {
            height: 70px;
            width: 100px;
        }

        label {
            font-size: 17px;
            width: 135px;
        }
    }

    .slick-next {
        background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 97%) 20%, rgb(255 255 255 / 0%) 100%) !important;

    }

    .slick-prev {
        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 97%) 20%, rgb(255 255 255 / 0%) 100%) !important;

    }

    .slick-disabled {
        background: unset !important;

        &:before {
            background-image: unset !important;
        }
    }

    .select-style {
        width: 160px;

        .demo-customized-select {
            font-size: 14px;
        }
    }

    .customized-select {
        em {
            font-size: 14px;
        }
    }

    .search_filter {
        padding: 20px 20px;

        .input-style {
            margin-bottom: 20px;
        }
    }

    .rental-search-form .beach-gear-search {
        width: 205px !important;
    }

    //    -----calender field------//
    .calender-field {
        background-position: 15px center;
        padding-left: 37px !important;
        background-size: 20px;
    }



}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

.filter-popper {
    z-index: 9999;
}

.serviceorder_timeinput {
    width: 100%;
    border: 1px solid rgb(238, 238, 238) !important;
    border-radius: 18px;
    padding-left: 20px !important;
}

.serviceorder_timeinput:focus-visible {
    outline: none;
}

.cart-times {
    .serviceorder_timeinput {
        width: 100%;
        border: 1px solid rgb(238, 238, 238) !important;
        border-radius: 18px;
        padding-left: 7px !important;
    }
}

.etatime {
    width: 94%;

    &:focus-visible {
        outline: none;
    }
}