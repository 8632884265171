// Footer Styles
.footer-container{
    color: color('tertiary');
    a{
        color: color('tertiary');
        font-size: 14px;
        &:hover{
            color: color('primary');
        }
    }
}
.primary-footer{
    border-top: 1px solid color('light-fourth');
    padding: 40px 0;
}
.secondary-footer{
    border-top: 1px solid color('light-fourth');
    padding: 40px 0 50px 0;
}
.footer-heading{
    color: color('secondary');
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
}
.footer-nav-list{
    li{
        padding-bottom: 10px;
    }
}
.footer-nav-inline-list{
    li{
        display: inline-flex;
        width: unset;
        position: relative;
        &:not(:last-child){
            padding-right: 20px;
            &:before{
                content: "";
                position: absolute;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: color('tertiary');
                right: 10px;
                top: 50%;
            }
        }
    }
}
.terms-col{
    margin: auto 0;
}
.copyright-col{
    text-align: center;
    margin: auto 0;
}
.socialize-col{
    margin: auto 0;
    text-align: end;
    a{
        &:not(:first-child){
            margin-left: 20px;
        }
    }
}




/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .primary-footer {
        padding: 40px 0;
    }
    .secondary-footer {
        padding: 30px 0 30px 0;
    }
    .terms-col{
        text-align: left;
        // margin-bottom: 30px;
        a{
            font-size: 13px;
        }
    }
    .copyright-col{
        text-align: left;
        margin-bottom: 30px;
    }
    .socialize-col{
        // text-align: left;
        a{
            &:not(:first-child){
                margin-left: 15px;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .primary-footer {
        padding: 40px 0;
    }
    .secondary-footer {
        padding: 40px 0 40px 0;
    }
    .terms-col{
        // text-align: left;
        // margin-bottom: 30px;
    }
    .copyright-col{
        text-align: left;
        margin-bottom: 30px;
    }
    .socialize-col{
        margin: 0;
        // text-align: left;
        a{
            &:not(:first-child){
                margin-left: 15px;
            }
        }
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .primary-footer {
        padding: 30px 0;
    }
    .secondary-footer {
        padding: 30px 0 30px 0;
    }
    // .terms-col{
    //     text-align: center;
    // }
    .copyright-col{
        text-align: center;
    }
    .socialize-col{
        // text-align: center;
        a{
            &:not(:first-child){
                margin-left: 15px;
            }
        }
    }

}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .primary-footer {
        padding: 30px 0;
    }
    .secondary-footer {
        padding: 30px 0 30px 0;
    }
    
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {
   
}
