.cart-page-section{
    .main-heading-block {
        .main-heading {
            margin-bottom: 0;
    
            .cart-items{
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-left: 45px;
                vertical-align: middle;
            }
        }
    }
    .view-all-btn{
        display: flex !important;
    }
}


.cart-item {
    padding-bottom: 40px;
    border-bottom: 1px solid color('light-fourth');
    margin-bottom: 40px;
    gap: 35px;

    .cart-item-img {
        width: 150px;
        height: 110px;
        object-fit: cover;
    }

    .cart-item-name {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 15px;
        display: inline-block;

        &:hover {
            color: color('primary') !important;
        }
    }
}

.secondary-host-img {
    img {
        border: 1px solid color('primary');
        border-radius: 50px;
    }
}

.secondary-host-heading {
    font-size: 18px !important;
    line-height: 25px !important;

    &:hover {
        color: color('primary') !important;
    }
}

.cart-item-value-each {
    font-size: 14px;
    line-height: 20px !important;
    display: inline-block;
    margin-bottom: 8px !important;
}

.cart-item-value {
    font-size: 24px;
    line-height: 30px !important;
}

.view-all-btn.primary-text {
    &:hover {
        color: color('tertiary') !important;
    }
}

.cart-checkout-sidebar {
    padding: 30px;

    .search-order {
        margin-bottom: 30px;
    }
}

.sidebar-form-heading {
    font-size: 20px;
    line-height: 28px !important;
}

.form-list {
    li {
        padding-bottom: 20px !important;
        border-bottom: 1px solid color('light-fourth');
        margin-bottom: 20px;
    }
}

// .carousel-item-title{
    
// }
.cart-product-name{
    width: 300px;
}

// Responsive Styles start
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .cart-item {
        padding-bottom: 25px;
        margin-bottom: 25px;
        gap: 15px;
        flex-wrap: wrap;
        padding: 15px;
        box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08) !important;
        border-radius: 15px;

        .cart-item-img {
            width: 70px;
            height: 70px;
        }

        .cart-item-name {
            font-size: 18px;
            margin-right: 30px;
        }
    }

    .cart-item-value {
        font-size: 18px;
        margin-bottom: 0;
    }

    .secondary-host-heading {
        font-size: 16px !important;
    }

    .cart-checkout-sidebar {
        padding: 25px;
    }

    .form-list {
        li {
            padding-bottom: 10px !important;
            margin-bottom: 15px;
        }
    }

    .cart-checkout-sidebar {
        margin-top: 20px;
        margin-bottom: 20px;

        .search-order {
            margin-bottom: 10px;
        }
    }
    .cart-date{
        .cart-info-block {
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 0 !important;
            margin-right: 0 !important;
            text-align: end !important;
        }
    }


    .cart-delete-option {
        justify-content: end !important;

        span {
            display: none;
        }
    }

    .cart-page-section {
        .main-heading-block {
            display: flex !important;
            margin-top: 10px;
        }
    }

    .main-heading-block {
        .main-heading {
            span {
                margin-left: 15px;
                vertical-align: unset;
            }
        }
    }

    .order-date-box {
        .border-right-radius {
            &+div {
                margin-left: 0 !important;
            }
        }
    }
    .main-heading-block{
        .main-heading{
            .cart-items {
                margin-left: 15px;
            }
        }
    } 
    .cart-page-section .cart-item .cart-item-img {
        width: 100% !important;
        height: 160px !important;
    }
    .cart-product-name{
        width: 90%;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .cart-item {
        padding-bottom: 25px;
        margin-bottom: 25px;
        gap: 20px;

        .cart-item-img {
            width: 120px;
        }

        .cart-item-name {
            font-size: 18px;
        }
    }

    .cart-item-value {
        font-size: 18px;
    }

    .secondary-host-heading {
        font-size: 16px !important;
    }

    .cart-checkout-sidebar {
        padding: 25px;
    }

    .form-list {
        li {
            padding-bottom: 10px !important;
            margin-bottom: 15px;
        }
    }

    .cart-checkout-sidebar {
        margin-top: 20px;

        .search-order {
            margin-bottom: 10px;
        }
    }
    .main-heading-block{
        .main-heading{
            .cart-items {
                margin-left: 15px;
            }
        }
    } 
    .cart-booking {
        >div{
           padding: 0 !important;
        }
    }
    .cart-booking{
        .start-date{
            .trip-date {
                margin-right: 0;
            }
        } 
    } 
    .cart-product-name{
        width: 200px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .cart-item {
        padding-bottom: 25px;
        margin-bottom: 25px;
        gap: 20px;

        .cart-item-img {
            width: 120px;
        }

        .cart-item-name {
            font-size: 19px;
        }
    }

    .cart-item-value {
        font-size: 20px;
    }

    .secondary-host-heading {
        font-size: 17px !important;
    }

    .cart-checkout-sidebar {
        padding: 25px;
    }

    .form-list {
        li {
            padding-bottom: 10px !important;
            margin-bottom: 15px;
        }
    }

    .cart-checkout-sidebar {
        margin-top: 20px;

        .search-order {
            margin-bottom: 10px;
        }
    }
    .main-heading-block{
        .main-heading{
            .cart-items {
                margin-left: 15px;
            }
        }
    } 
    .cart-booking{
        .start-date{
            .trip-date {
                margin-right: 0;
            }
        } 
    } 
    .cart-product-name{
        width: 350px;
    }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .cart-item {
        padding-bottom: 30px;
        margin-bottom: 30px;
        gap: 20px;
    }

    .cart-item-value {
        font-size: 22px;
    }
    .cart-booking{
        .start-date{
            .trip-date {
                margin-right: 0;
            }
        } 
    } 
    .cart-product-name{
        width: 200px;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

@media (min-width: 365px) and (max-width: 576px) { 
    .cart-date{
        display:'unset !important',
    }
    .cart-date-range{
        width:100% !important;
        .start-date{
            width:50% !important;
        }
       
    }
   }

   @media (min-width: 900px) and (max-width: 904px){
    .cart-section-block{
        flex-wrap: nowrap !important;
    }
   }
  



