.uploadPhotoWrapper {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
}

.uploadPhotoInput {
    cursor: pointer;
    position:absolute;
    top: 0;
    right: 0;
    opacity: 0;
    -moz-opacity: 0;
}
