.full-calender{
    .fc .fc-daygrid-day-top{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        margin-bottom: 10px;
        a{
            font-size: 15px; 
            color: color('secondary');
            height: 27px;
            width: 27px;
            background-color: transparent;
            border-radius: 50%;
            text-align: center;
        }
    }
    .fc-scrollgrid-sync-inner{
        a{
            color: color('secondary');
            font-size: 16px;
            padding-bottom: 12px;

        }
    }
    .fc-scroller-liquid-absolute{
        overflow: hidden !important;
    }
  
.fc-day-today{
    background-color: transparent  !important;
    .fc-daygrid-day-top{
        a{
            height: 27px;
            width: 27px;
            border-radius: 50%;
            background: color('primary');
            color: color('light');
            text-align: center;
            padding-bottom: 8px;
        }
    }
}
.fc-header-toolba{
    .fc-today-button{
        background-color: none !important;
    }
}
.fc-theme-standard th {
    border: 0;
}
table{
    border: 0 !important;
}
 .fc-col-header {
    .fc-scrollgrid-sync-inner{
        a{
            padding-bottom: 16px;
        }
    } 
 }  
   .fc-toolbar-chunk{
    .fc-today-button{
        background-color: none !important; 
    }
   }
   .fc .fc-button-primary:disabled {
    background-color: transparent;
    border-color: transparent;
    color:color('tertiary'); 
} 
   .fc .fc-button-primary {
    background-color: transparent;
    border-color: transparent;
    color:color('tertiary'); 
}
.fc .fc-scrollgrid-section-liquid>td {
   border: 0;
}
.fc-daygrid-day-events::before{
    background: color('primary');
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 0 auto;
}
.fc-event-today{
    i{
        display: none !important;
    }
    b{
        display: none;
    }
    
}
.fc-theme-standard td{
    border: 1px solid color('light-sixth');
}
.fc .fc-button-primary:hover {
    background-color: transparent;
    border-color: transparent;
    color:color('tertiary'); 
}
.fc .fc-toolbar-title {
    font-size: 20px !important;
    font-weight: 400;
    color: color('secondary');
}

}
.full-calendar{
    .service-list-left-side {
        li{
            a{
                img{
                    margin-right: 10px !important;
                }
            }
        }
       
       
    }
}


.date-info-popover{
    width: 297px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px #09060F1A;
    background: color('light');
    padding: 18px;
    .feature-listing li {
        margin-right: 20px;
    }
    .listing-visible {
        gap: 0px;
        margin-bottom: 20px;
    }
    .time-field {
        display: flex;
        gap:10px;
    }
    .search-result-box-2 {
        padding: 8px 0 !important;
        margin-bottom: 4px;
        .input-style{
            input {
                height: 30px;
            }
        } 
}
}
.full-calendar-detail{
    h6{
        font-size: 18px;
        font-weight: 400;
        color: color('secondary');
        // margin-right: 20px;
    }
    span{
        font-size: 16px;
        font-weight: 400;
    }
  
}
.rentor-btn{
    padding: 6px 15px !important;
}
.view-more-btn{
    padding: 6px 15px !important;
}
.calender-btn{
    display: flex;
    gap: 17px;
    flex-wrap: wrap;
}
.bottom-space{
    margin-bottom: 10px;
}
  // X-Small devices (portrait phones, less than 576px) /
  @include media-breakpoint-between(xs, sm) {
    .date-info-popover .time-field .input-style:not(.input-style.end-date) {
        border-bottom:0;
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .date-info-popover {
        width: 400px !important;
    }
    .date-info-popover .search-result-box-2 .input-style {
        height: 35px;
        width: 100%;
    }
    .full-calender .fc .fc-daygrid-day-top {
        padding-top: 5px;
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .date-info-popover {
        width: 347px !important;
    }
    .date-info-popover .search-result-box-2 .input-style {
        height: 35px;
        width: 100%;
    }
  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
   
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
     
}