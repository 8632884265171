.form-plus-icon{
    display: flex;
    align-items: center;
   button{
    &:hover{
        background: none;
    }
   }
}
.plus-img{
    img{
        margin-left: 10px;
    }
}
.form-full-width{
    width: 100% !important;
}
.small-heading{
    font-size: 20px;
   color: color('secondary');
  font-weight: 400;
  margin-bottom: 0;
}
.dotted-border{
    border-bottom: 1px dotted color('light-fourth');
    margin-bottom:20px;
    padding-bottom:20px;
}
.small-radius{
    border-radius: 18px !important;
}
.service-heading{
    h2{
        font-size: 24px !important;
        color: color('secondary');
        font-weight: 400;
    }
}
.service-listing-form{
   .image-bottom{
    .main-heading{
        margin-bottom: 40px;
    }
   }
   .textfield-area-one{
    height: 160px !important;
    border-radius: 18px;
    &:focus-visible {
        outline: color('light-tertiary') !important;
    }
    
   }
.textfield-area {
    height: 170px !important;
    background: color('light');
    border: 1px solid color('light-tertiary');
    border-radius: 18px;
    padding: 18px;
    font-size: 16px;
    font-weight: 400;

    &:focus-visible {
        outline: color('light-tertiary') !important;
    }
    &::placeholder {
        color: color('secondary');
        font-family: 'Jost';
        font-weight: 400;
        font-size: 16px;
    }
}
   .slider-range-bar{
    color:color('primary') !important;
   }
   .uplaod-image-block{
    position: relative;
    display: flex;
    gap: 16px;
    cursor: pointer;
    flex-wrap: wrap;
}

}
.upload-image{
    border: 1px dotted color('light-seven');
    border-radius: 18px;
    height:  215px;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    object-fit: cover;

    img{
        height:48px;
        width:48px !important;
    }
}
.upload-src-image{
    position: relative;
    img{
        border: 1px dotted color('light-seven');
        border-radius: 18px;
        height: 215px;
        width: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        object-fit: cover;
    }
    .image-src-close{
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.451);
        color: color('light');
        width: 25px;
        height: 25px;
        border-radius: 50rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        font-size: 20px;
        padding: 4px 4px;
    }
}
.plus-img{
    img{
        height: 38px;
        width: 38px;
    }
}
.submit-btn{
    padding: 12px 32px !important;
}
.submit-btn-end{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 10px;
}
// ----services listing page----------------// 
.mobile-link-btn{
    padding: 15px 0 !important;
    border-radius: 18px !important;
    width: 100%;
    font-size: 15px;
}
.mobile-bottom-btn{
    position: fixed;
    bottom: 0;
    padding: 20px;
    text-align: center;
    width:100%;
    background: color('light');
    box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08);
    left: 0;
    z-index: 2;
    display: none;
}
// .left-back-arrow{
//     img{
//         margin-right: 12px;
//         background: color('light-fourth');
//         border-radius: 12px;
//         padding: 12px 8px;
//     }
//     .view-all-btn{
//         font-size: 20px;
//         font-weight: 400;
//         line-height: 28px;
//     }
// }
.grid-4 {
    .vertical-card:hover:nth-child(4n+1):nth-last-child(-n+4),
    .vertical-card:nth-child(4n+1):nth-last-child(-n+4) ~ .vertical-card:hover{
        // height: 580px;
    }
} 
.fill-plus-icon{
    img{
        margin-right: 12px;  
    }
}
.service-top-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 34px;
    margin-top: 10px;
    gap: 15px;
}
.service-listing{
    padding-top: 40px;
   .service-listing-toggle{
    img{
        margin-right: 12px;
    }
   }
   .left-side-menu-show{
    a:hover{
        background: none !important;
        color: color('primary') !important;
      }
    .listing-text{
        font-family: 'Jost';
        font-weight: 400;
        font-size: 16px;
        color: color('tertiary');
        padding-left: 0;
        &.active{
          color: color('primary');
         }
    }
    li:first-child a{
        color: color('secondary');
    }

   }
//    .grid-4{
//     grid-gap: 22px;
//     display: grid;
//     grid-template-columns: repeat(4,minmax(0,1fr));
//    }
}
.service-heading{
    .filter-sub-value{
        color:color('light-eight');
    }
}
.featuring-badge{
    .edit-bg{
        background: rgba(9, 6, 15, 0.5);
        padding: 5px;
        border-radius: 5px;
        margin-left: 8px;
    }
}
.uplaod-image-block{
    .featuring-block{
        .order-badge{
            border-radius: 3px !important;
            margin-left: 16px;
            padding: 2px 9px;
            margin-bottom: 0;
        }
    }

}
.grid-4{
    grid-gap: 22px;
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
   }

/* // -----add info-----// */

.service-form{
    h4{
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 15px;
        color: color('secondary');
    }
    .main-heading{
        font-size: 25px;
        margin-bottom: 0;
    }
}
.service-listing-form{


.service-heading {
    h2{
        font-size: 30px !important;
        margin-bottom: 0;
    }
}
}
.service-listing-form .select-style > div {
    height: 60px;
}
.service-listing-form{
    .submit-btn {
       min-width: 160px;
    }
} 

// ---service-listing responsive start----//
/* // X-Small devices (portrait phones, less than 576px) / */
@include media-breakpoint-between(xs, sm){
    .dotted-border {
        margin-bottom:10px;
        padding-bottom: 10px;
    }
    .service-top-header {
        padding-bottom: 20px;
    }
    .plus-img img {
        height: 25px;
        width: 25px;
    }
    .plus-img img {
        margin-left: 0;
    }
    // .service-listing-form .upload-image {
    //     padding:46px;
    //     border-radius: 18px;
    //     width: 100%;
    //     flex-wrap: wrap;
    // }
    // .service-listing-form .upload-image img {
    //     height: 35px;
    //     width: 35px;
    // } 
    .service-listing-form .uplaod-image-block {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .upload-image{
        height: 170px;
        width: 100%;
        img{
            height:35px;
            width:35px !important;
        }
    }
    .upload-src-image{
        letter-spacing: 0px;
        img{
            height: 170px;
            width: 100%;
        }
    }
    .service-listing-form .uplaod-image-block {
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
    .listing-text{
        &.active{
          color: color('primary');
          border-bottom: 2px solid color('primary');
         }
    }

// .uplaod-image-block {
//     div{
        
//         position: relative;
//         width: 100%;
//         img{
//             width:216px !important;
//             object-fit: cover;
//             height: 220px;
//         }
//         i{
//             position: absolute;
//             font-size: 35px;
//             margin-top: -18px;
//             top: -15px;
//             right: -5px;
//         }
//     }
// }
.service-listing-form{
    .submit-btn-end {
        margin-top: 15px;
        gap: 15px 10px;
    }
    .submit-btn {
        min-width: 100%;
     }
} 
.submit-btn {
    padding: 10px 20px !important;
}
.submit-btn-icon{
    font-size: 20px;
} 
.service-listing-form .select-style > div, .service-listing-form .input-style {
    height: 50px;
}
}
// .error .css-1dqib1v-MuiFormControl-root-MuiTextField-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
//     border:1px solid red !important;
// }
/* // Small devices (landscape phones, 576px and up) / */
@include media-breakpoint-between(sm, md) {
    .dotted-border {
        margin-bottom:14px;
        padding-bottom: 14px;
    }
    .service-top-header {
        padding-bottom: 20px;
    }
    .plus-img img {
        height: 30px;
        width: 30px;
    }
    // .service-listing-form .upload-image {
    //     padding:46px;
    //     border-radius: 18px;
    //     width: 240px;
    // }
    // .service-listing-form .upload-image img {
    //     height: 35px;
    //     width: 35px;
    // }
    .service-listing-form .uplaod-image-block {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .upload-image{
        height: 200px;
        width: 100%;
        img{
            height:35px;
            width:35px !important;
        }
    }
    .upload-src-image{
        letter-spacing: 0px;
        img{
            height: 200px;
            width: 100%;
        }
    }
    .listing-text{
        &.active{
          color: color('primary');
          border-bottom: 3px solid color('primary');
         }
    }
    .service-listing-form{
        .submit-btn {
           min-width: 48%;
        }
    }
    .submit-btn {
        padding: 10px 20px !important;
    }
    .submit-btn-end {
        gap: 15px 10px;
    }
    .submit-btn-icon{
        font-size: 20px;
    } 
}

/* // Medium devices (tablets, 768px and up) / */
@include media-breakpoint-between(md, lg) {
    .dotted-border {
        margin-bottom:14px;
        padding-bottom: 14px;
    }
    .service-top-header {
        padding-bottom: 20px;
    }
    .plus-img img {
        height: 30px;
        width: 30px;
    }
    // .service-listing-form .upload-image {
    //     padding:46px;
    //     border-radius: 18px;
    //     width: 250px;
    // }
    // .service-listing-form .upload-image img {
    //     height: 35px;
    //     width: 35px;
    // }
    .service-listing-form .uplaod-image-block {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .upload-image{
        height: 200px;
        width: 100%;
        img{
            height:35px;
            width:35px !important;
        }
    }
    .upload-src-image{
        letter-spacing: 0px;
        img{
            height: 200px;
            width: 100%;
        }
    }
    .listing-text{
        &.active{
          color: color('primary');
        //   border-bottom: 2px solid color('primary');
         }
    }
    .service-listing-form{
        .submit-btn {
           min-width: 30%;
        }
    } 
    .submit-btn {
        padding: 10px 15px !important;
    }
    .submit-btn-end {
        gap: 10px 12px;
    }
    .submit-btn-icon{
        font-size: 20px;
    }
  
}

/* // Large devices (desktops, 992px and up) / */
@include media-breakpoint-between(lg, xl) {
    .dotted-border {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .service-top-header {
        padding-bottom: 20px;
    }
    .plus-img img {
        height: 30px;
        width: 30px;
    }
    // .service-listing-form .upload-image {
    //     padding: 60px;
    //     border-radius: 18px;
    //     width: 240px;
    // }
    // .service-listing-form .upload-image img {
    //     height: 40px;
    //     width: 40px;
    // }
    .service-listing-form{
        .submit-btn {
           min-width: unset;
        }
    } 
    .upload-image{
        height:  195px;
        width: 240px;
        img{
            height:40px;
            width:40px !important;
        }
    }
    .upload-src-image{
        letter-spacing: 0px;
        img{
            height: 195px;
            width: 240px;
        }
    }
}

.imagebgpreview{ 
    img
    {
        display: none;
    }

    h2{
        display: none;
    }

    .featuring-block{
        display: none;
    }
}

