.daterangepicker {
    box-shadow: 0px 0px 20px rgba(9, 6, 15, 0.1) !important;
    border-radius: 16px !important;
    border: 0 !important;
    padding: 15px 15px !important;
    flex-wrap: wrap !important;
    width: 570px !important;

    .right-fill {
        border-radius: 50% 0 0 50% !important;
    }

    .left-fill {
        border-radius: 0 50% 50% 0 !important;
    }

    &.single {
        width: 375px !important;
        padding-left: fixed!important;
        top: 50%!important;
        left: 50%!important;
        transform: translate(-50%, -50%)!important;

        .monthselect,
        .yearselect {
            padding: 10px;
            border-radius: 15px;
            -webkit-appearance: none;
            appearance: none;
        }

        .table-condensed {

            >thead,
            >tbody,
            >tfoot {
                >tr {

                    >th,
                    >td {
                        padding: 15px;
                    }
                }
            }
        }
    }

    // height: 760px !important;
    &:before,
    &:after {
        display: none !important;
    }

    .ranges {
        order: 12;
        width: 100% !important;

        ul {
            display: flex;
            float: left;
            width: 100% !important;
            flex-wrap: wrap;
        }

        li {
            white-space: nowrap !important;
            border: 1px solid color('tertiary') !important;
            border-radius: 50px !important;
            margin-right: 5px;
            margin-top: 5px !important;

            &.active {
                background-color: color('light') !important;
                color: color('secondary') !important;
            }
        }
    }

    .drp-calendar.left,
    .drp-calendar.right {
        padding: 0 !important;
        border-left: 0 !important;
    }

    th,
    td {
        width: 40px !important;
        height: 35px !important;
    }

    thead {
        tr {
            th {

                &.next,
                &.prev {
                    width: 36px !important;
                    height: 36px !important;
                    border-radius: 50% !important;
                    background-color: color('light-fourth');

                    span {
                        border: solid color('secondary') !important;
                        border-width: 0 1px 1px 0 !important;
                        padding: 4px !important;
                    }

                    span:not(th.prev span) {
                        margin: 6px 4px 0 0 !important;
                    }

                    span:not(th.next span) {
                        margin-left: 3px !important;
                    }
                }

                &.month {
                    font-family: $primaryFont;
                    font-size: 20px !important;
                    font-weight: 400;
                    line-height: 16px !important;
                    color: color('secondary');

                }
            }

            &:last-child {
                color: color('tertiary');

                th {
                    font-family: $primaryFont;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;

                    &:nth-last-child(-n+2) {
                        color: color('primary');

                    }
                }

            }
        }
    }

    tbody {
        td {
            font-family: $primaryFont;
            font-size: 15px !important;
            font-weight: 400;
            line-height: 20px !important;

            &.off {
                color: color('tertiary') !important;
                background-color: transparent !important;
            }

            &.active,
            &.active:hover {
                background-color: color('primary') !important;
            }

            &.start-date.end-date {
                border-radius: 50%;
            }

            &.start-date {
                border-radius: 20px 0 0 20px;

            }

            &.end-date {
                border-radius: 0 20px 20px 0;

            }

            &.in-range {
                background-color: rgb(255 65 13 / 13%);
            }

            &:hover:not(td.active, td.off) {
                border-radius: 50% !important;
                background-color: color('light-primary') !important;
            }

            &:nth-last-child(-n+2) {
                color: color('primary') !important;
            }

            &.in-range {
                &.available {
                    &:hover:not(.active) {
                        border-radius: 0 !important;
                    }
                }
            }

            &.weekend {
                &.active:not(.off.ends) {
                    color: color('light') !important;
                }
            }

            &.off,
            &.off.in-range,
            &.off.start-date,
            &.off.end-date,
            &.off.ends {
                background-color: color('light') !important;
                border-color: transparent !important;
            }
        }
    }
}

.daterangepicker.show-calendar .drp-buttons {
    display: none !important;
}

// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .daterangepicker {
        max-width: 295px !important;
        min-width: 303px !important;
        width: unset !important;
        z-index: 1300 !important;

        .drp-calendar.left,
        .drp-calendar.right {
            max-width: 100%;
        }

        th,
        td {
            width: 40px !important;
            height: 40px !important;
        }
    }

}

@media (min-width: 0) and (max-width: 575.98px) {
    .daterangepicker {
        z-index: 1300 !important;
        top: 100% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

        .drp-calendar.left,
        .drp-calendar.right {
            max-width: 260px;
        }

        &.single {
            // width: 375px !important;
            position: fixed !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
    
            .monthselect,
            .yearselect {
                padding: 10px;
                border-radius: 15px;
            }
    
            .table-condensed > thead > tr > th {
                padding: 10px !important;
            }

            .table-condensed > tbody > tr > td {
                padding: 10px !important;
            }
        }
    }
}


// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .daterangepicker {
        z-index: 1300 !important;

        .drp-calendar.left,
        .drp-calendar.right {
            max-width: 260px;
        }

        &.single {
            width: 375px !important;
            padding-left: fixed!important;
            top: 50%!important;
            left: 50%!important;
            transform: translate(-50%, -50%)!important;
    
            .monthselect,
            .yearselect {
                padding: 10px;
                border-radius: 15px;
            }
    
            .table-condensed {

            >thead,
            >tbody,
            >tfoot {
                >tr {

                    >th,
                    >td {
                        padding: 15px;
                    }
                }
            }
        }
        }
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .daterangepicker {
        z-index: 1300 !important;
    }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}