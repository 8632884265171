@import "../abstracts/variables";

html, body {
  font-family: $primaryFont !important;
}

// Header Styles
h1, h2, h3, h4, h5, h6 {
  font-family: $primaryFont !important;

  a {
    font-family: $primaryFont !important;
  }
}


// Text Styles

