  //  ----register start-----//
  .modalregister{
    .modal-body{
        img{
            margin-right: 15px;
        }
        .register-check-box{
            font-size:16px !important;
            font-weight:400;
            color:color('light-seven')
        }

    }
}
.react-datepicker__input-container{
    .outline-input{
        width: 100%;
    }
}
.react-datepicker__month-container{
    padding: 20px;
    .react-datepicker__header{
        span{
            cursor: pointer;
            img{
                margin-right: 0;
            }
        }

    }
}
.react-datepicker{
    font-family: $primaryFont !important;
    border: 0 !important;
    border-radius: 16px !important;
    box-shadow: 0px 0px 20px rgba(9, 6, 15, 0.1);
    width: 350px;
    .react-datepicker__header{
        background-color: transparent;
        border-bottom: 0;
        border-top-left-radius: 0;
        padding: 0;
    }
    .react-datepicker__day-names{
        .react-datepicker__day-name{
            font-size: 13px;
            font-weight: 400;
            color:color('tertiary');
            &:nth-last-child(-n+2) {
                color:color('primary');
            }
        }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--keyboard-selected:hover{
        background-color: color('primary');
        border-radius: 50rem;
        color: color('light') !important;
    }
    .react-datepicker__day:hover:not(.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover){
        border-radius: 50rem;
        background-color: color('light-primary');
    }
    .react-datepicker__week{
        .react-datepicker__day{
            &:nth-last-child(-n+2) {
                color:color('primary');
            }
        }
    }
    .react-datepicker__day--outside-month{
        visibility: hidden;
    }
    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name{
        color: color('secondary');
        line-height: 30px !important;
        height: 30px!important;
        width: 30px!important;
        margin: 2px 7px 2px 6px !important;
    }
}
.date-select{
    padding: 20px;
    li{
        &>div{
            padding: 8px 5px;
            &:hover{
                background-color: transparent !important;
            }
        }
        span{
            font-size: 15px;
            color: color('secondary');
        }
        &.selected-item{
            span{
                color: color('primary');
            }
        }
        .Mui-selected{
            background-color: transparent !important;
        }
    }
}
.date-select-header{
    span{
        font-size: 13px;
        line-height: 16px;
        color: color('tertiary');
        padding: 0 5px 8px;
        display: block;
        text-align: left;
    }

}
.date-scrollbar{
    height: 220px !important;
    & > div:nth-child(3){
        width: 1px !important;
        background-color: color('light-sixth');
        & > div{
            background-color: color('primary') !important;
        }
    }
}
.select-month{
    font-weight: 400;
    line-height: 16px;
    p{
        font-size: 20px;
        margin-bottom: 5px;
        color: color('secondary');
    }
    span{
        font-size: 12px;
        color: color('tertiary');

    }

}
.datepicker-wrapper{
    .MuiPickersDay-today{
        border: 0 !important;
        background: #ff411017 !important;
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .Mui-selected.MuiPickersDay-today, .Mui-selected {
        border: 0 !important;
        background-color: #ff4110 !important;
        color: #fff !important;
    
    }
}
/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .modalheader {
        margin-bottom: 20px;
    }
    .modalregister .modal-body img {
        margin-right: 5px;
    }
    .modalregister .modal-body .register-check-box {
        margin-bottom: 15px;
    }
    // .modal-body .input-style {
    //     margin-bottom: 10px;
    // }
    .modalheader h2 {
        font-size: 22px;
    }
    .modal-body .header-submit-btn {
        padding: 10px 0;
        margin-bottom: 0px;
    }
    .register-check-box span{
        font-size:16px;
    }
    // .modalheader .modal-close-btn Button {
    //     margin-top: -10px;
    // }
    .outline-input input  {
        padding: 0;
    }
    .sub-text {
        text-align: center;
    }
    .react-datepicker {
        width: 300px;
        margin: 10px;
        .react-datepicker__day-name, 
        .react-datepicker__day, 
        .react-datepicker__time-name {
            margin: 3px !important;
            width: 31px !important;
        }
    }
    .react-datepicker__month-container {
        padding: 20px 15px;
    }
    .date-select {
        padding: 20px 15px;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .modalheader {
        margin-bottom: 25px;
    }
    .modalregister .modal-body img {
        margin-right: 5px;
    }
    .modalregister .modal-body .register-check-box {
        margin-bottom: 20px;
    }
    // .modal-body .input-style {
    //     margin-bottom: 15px;
    // }
    .modalheader h2 {
        font-size: 22px;
    }
    .modal-body .header-submit-btn {
        padding: 15px 0;
        margin-bottom:0px;
    }
    .register-check-box span{
        font-size:16px;
    }
   
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .modalheader {
        margin-bottom: 30px;
    }
    .modalregister .modal-body img {
        margin-right: 10px;
    }
    .modalregister .modal-body .register-check-box {
        margin-bottom: 20px;
    }
    // .modal-body .input-style {
    //     margin-bottom: 15px;
    // }
    .modalheader h2 {
        font-size: 22px;
    }
    .modalfooter .social-media-btn {
        margin-bottom: 18px;
    }
    .modal-body .header-submit-btn {
        padding: 17px 0;
    }

}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .modalheader {
        margin-bottom: 30px;
    }
    .modalregister .modal-body img {
        margin-right: 10px;
    }
    .modalregister .modal-body .register-check-box {
        margin-bottom: 20px;
    }
    // .modal-body .input-style {
    //     margin-bottom: 15px;
    // }
    .modalfooter .social-media-btn {
        margin-bottom: 18px;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {
   
}