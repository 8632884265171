.heading-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}
.right-space{
    margin-right: 15px !important;
}
.top-section{
    padding-top: 40px;
}
.inner-main-heading {
    h3{
        font-size: 24px;
        font-weight: 400;
        margin-bottom:12px;
}
    span{
        font-size: 16px;
        font-weight: 400;
    }
}
.host-order{
    .top-side-heading{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
      
    }
}
.service-listing-bg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    .review-section{
        display: flex;
        align-items: center;
        h2{
            font-size: 22px !important;
            font-weight: 400 !important;
        }
        .right-space{
            margin-right: 15px;
        }
        .upload-img{
            height: 24px;
            width: 24px;
        }
        .right-space-2{
            margin-right: 60px;
        }
    }
  .service-logo{
    margin-right: 20px;
    flex-shrink: 0;
    img{
       height: 95px;
       width: 95px;
       border: 7px solid color('primary');
       border-radius: 50%;
    } 
}
.horizontal-service-list-card{
    display: flex;
    align-items: center;
    .horizontal-service-listing{
        .listing-visible {
            gap: 12px;
        }
        .main-heading-block{
            margin-bottom: 0;
            justify-content: unset;
            span{
                font-size: 14px;
                color: color('primary');
                font-weight: 400;
                margin-left: 16px;
                display: inline-block;
            }
            .main-heading{
                display: flex;
                margin-bottom: 0;
                margin-right: 15px;
            }
            .review-section{
                display: flex;
            }
            .featuring-badge{
                img{
                    margin: 0 190px  0 110px;
                }
            }
        }
    }

}
.card-btn-2{
    margin-left: 15px;
    flex-shrink: 0;
    .contact-us-btn{
        padding: 6px 45px !important;
    }
    
}
}
.host-profile{
    .pricing{
        margin-bottom: 10px;
    }
    .add-group-btn{
        margin-bottom: 10px;
        min-width: unset;

        button{
            min-width:unset;
        }

       p{
        margin-bottom: 0;
       }
    }
    .about-us-text{
        p{
            font-size: 16px;
            font-weight: 400;
            color: color('dark-tertiary');
            margin-bottom: 30px;
        }
       
    }
     .read-more-link{
       text-decoration: underline !important;
    }
    .reduce-bottom-space{
        margin: 30px 0 10px 0;
    }
}
.big-grey-btn{
    padding: 7px 34px !important;
    margin-bottom: 15px !important;
}
.more-info{
    text-decoration: underline !important;
    color: color('primary') !important;
    margin-left: 0 !important;
}
// .vertical-card{
//         .big-grey-btn:hover{
//             background: color('primary') !important;
//             color: color('light') !important;
//             border-color: color('primary') !important;
//         }
   
// } 
.vertical-card-divider {
    border-bottom: 1px solid #EEEEEE;
    margin: 40px 0;
}
.small-grey-heading{
    font-size: 14px;
    font-weight: 400 !important;
}
.medium-black-heading{
    font-size: 18px;
    font-weight: 400 !important;
}
.about-us-inner-heading{
    display: flex;
    gap: 60px;
    margin-bottom: 30px;
}
.text-truncate-4line{
    display: -webkit-box;
    -webkit-line-clamp:4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.host-order{
    h5{
        font-size: 16px;
        font-weight: 400;
    }
    padding: 25px;
    span{
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 10px;
        // display:block;
    }
    .right-side-heading{
        font-size: 12px;
        font-weight: 400;
    }
    .order-right-side{
        display: flex;
        align-items: center;
    }
    .white-border{
        border-bottom: 1px solid color('light');
        margin:  0px  0 20px 0;
    }
    .input-style{
        .input-image{
            img {
                width: 15px;
            }
        } 
    } 
    .fill-plus-icon {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .cart-summary{
        li{
            padding: 0;
        }
        .summary-price{
            &.summary-price-total{
                span {
                    font-size: 18px;
                    color: color('primary');
                }
            } 
        }
        .booking-btn{
            padding: 10px 60px;
        }
    }

}
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .big-grey-btn {
        // padding: 7px 24px !important;
        margin-bottom: 10px !important;
        width: 100%;
    }
    .host-order .cart-summary .booking-btn {
        padding: 10px 38px;
        width: 100%;
    }
    .top-section {
        padding-top: 20px;
    }
    .top-side-heading {
        margin-bottom: 10px;
    }
    .service-listing-bg .service-logo img {
        height: 75px;
        width: 75px;
    }
    .host-order {
        padding: 20px;
    }
    .host-order .white-border {
        margin: 10px 0 10px 0;
    }
    .host-order span {
        margin-bottom: 7px;
    }
    .host-profile .grid-4 {
        grid-gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .host-profile .cart-summary {
        text-align: center;
    }
    .service-listing-bg .service-logo img {
        border: 5px solid #FF4110;
    }
    .host-order .fill-plus-icon {
        margin-bottom: 15px;
    }
    .host-order .beach-detail-grid {
        margin-bottom: 0;
    }
    .vertical-card-divider {
        margin: 15px 0 !important;
    }
    .service-listing-bg {
        padding: 20px;
    }
    .service-listing-bg .card-btn-2 .contact-us-btn {
        // padding: 6px 40px !important;
        width: 100%;
    }
    .inner-calendar{
        width: 50%;
    }
    .service-listing-bg {
        flex-direction: column;
    }
    .service-listing-bg .horizontal-service-list-card {
        margin-bottom: 20px;
        flex-direction:column;
        width: 100%;
    }
    .service-listing-bg .card-btn-2 {
        margin-left: 0;
        width: 100%;
    }
    .inner-main-heading h2 {
        font-size: 22px;
        margin-bottom: 0;
    }
    .about-us-inner-heading {
        margin-bottom: 20px;
    }
    .host-profile .about-us-text p {
        margin-bottom: 15px;
    }
    .service-listing-bg .service-logo {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .right-space {
        margin-bottom: 0;
    }
    .host-profile .fill-plus-icon img {
        display: inline-block;
    }
    .host-profile .fill-plus-icon {
        display: block;
    }
    .host-profile .reduce-bottom-space {
        margin: 0;
    }
    .inner-main-heading .about-inner-heading {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block {
        // margin-bottom: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .service-listing-bg .review-section .right-space-2 {
        margin-right: 20px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .main-heading {
        margin-right: 0;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        display: unset;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .featuring-badge img {
        margin: 0px 30px 0 0;
    }
    .service-listing-bg {
        padding: 20px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block span {
        margin-left: 0;
    }
    .host-profile .horizontal-service-listing .main-heading-block span{
        margin-left: 0 !important;
    }
    .host-profile .horizontal-service-listing .main-heading-block button{
        width: 100%;
    }
    .host-profile .horizontal-service-listing{
        width: 100%;
    }
    .host-profile .horizontal-service-list-card .horizontal-service-listing .feature-listing{
        margin: 15px 0;
    }
    .host-profile .heading-search{
        margin-top: 15px;
        margin-bottom: 0;
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .big-grey-btn {
        padding: 7px 24px !important;
        margin-bottom: 10px !important;
        width: 100%;
    }
    .host-order .cart-summary .booking-btn {
        padding: 10px 38px;
        width: 100%;
    }
    .top-section {
        padding-top: 20px;
    }
    .top-side-heading {
        margin-bottom: 0;
    }
    .service-listing-bg .service-logo img {
        height: 75px;
        width: 75px;
    }
    .host-order {
        padding: 20px;
    }
    .host-order .white-border {
        margin: 10px 0 0 0;
    }
    .host-order span {
        margin-bottom: 10px;
    }
    .host-profile .grid-4 {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .host-profile .cart-summary {
        text-align: center;
    }
    .service-listing-bg .service-logo img {
        border: 5px solid #FF4110;
    }
    .host-order .fill-plus-icon {
        margin-bottom: 15px;
    }
    .host-order .beach-detail-grid {
        margin-bottom: 0;
    }
    .vertical-card-divider {
        margin: 15px 0 !important;
    }
    .service-listing-bg {
        flex-direction: column;
        margin-bottom: 15px;
        flex-direction: column;
    }
    .service-listing-bg .card-btn-2 .contact-us-btn {
        // padding: 6px 40px !important;
        width: 100%;
    }
    .inner-calendar{
        width: 50%;
    }
    .service-listing-bg .horizontal-service-list-card {
        margin-bottom: 10px;
        width: 100%;
        flex-direction: column;
    }
    .service-listing-bg .card-btn-2 {
        margin-left: 0;
        width: 100%;
    }
    .inner-main-heading .about-inner-heading {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .about-us-inner-heading {
        margin-bottom: 20px;
    }
    .host-profile .about-us-text p {
        margin-bottom: 15px;
    }
    .host-profile .fill-plus-icon img {
        display: block;
    }
    .host-profile .fill-plus-icon {
        display: flex;
    }
    .host-profile .reduce-bottom-space {
        margin: 0;
    }
    .inner-main-heading h2 {
        margin-bottom: 0;
    }
    .service-listing-bg .review-section .right-space {
      margin-bottom: 0;
    }
    .service-listing-bg .review-section .right-space-2 {
        margin-right: 30px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .featuring-badge img {
        margin: 0;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .main-heading {
        width: 100%;
        display: block;
        text-align: center;
        margin-right: 0;
    }
    .service-listing-bg {
        padding: 20px;
    }
    .service-listing-bg .service-logo{
        margin-right: 0;
    }
    .host-profile .horizontal-service-listing .main-heading-block{
        flex-direction: column;
        align-items: center;
    }
    .host-profile .horizontal-service-listing .main-heading-block span{
        margin-left: 0 !important;
        margin-right: 20px;
    }
    .host-profile .horizontal-service-listing .main-heading-block button{
        width: 100%;
    }
    .host-profile .horizontal-service-listing{
        width: 100%;
    }
    .host-profile .horizontal-service-list-card .horizontal-service-listing .feature-listing{
        margin: 15px 0;
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .big-grey-btn {
        padding: 7px 24px !important;
        margin-bottom: 10px !important;
    }
    .host-order .cart-summary .booking-btn {
        padding: 10px 38px;
    }
    .top-section {
        padding-top: 30px;
    }
    .top-side-heading {
        margin-bottom: 0;
    }
    .service-listing-bg .service-logo img {
        height: 85px;
        width: 85px;
    }
    .host-order {
        padding: 20px;
    }
    .host-order .white-border {
        margin: 10px 0 20px 0;
    }
    .host-order span {
        margin-bottom: 10px;
    }
    .host-profile .grid-4 {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .host-profile .cart-summary {
        text-align: center;
    }
    .service-listing-bg .service-logo img {
        border: 5px solid #FF4110;
    }
    .host-order .fill-plus-icon {
        margin-bottom: 15px;
    }
    .host-order .beach-detail-grid {
        margin-bottom: 0;
    }
    .vertical-card-divider {
        margin: 20px 0;
    }
    .service-listing-bg {
        padding: 25px;
    }
    .service-listing-bg .card-btn-2 .contact-us-btn {
        padding: 6px 40px !important;
    }
    .inner-calendar{
        width: 50%;
    }
    .host-profile .reduce-bottom-space {
        margin: 14px 0;
    }
    .inner-main-heading h2 {
        margin-bottom: 0;
    }
    .inner-main-heading .about-inner-heading {
        margin-bottom: 20px;
    }
    .service-listing-bg {
        margin-bottom: 10px;
    }
    .big-grey-btn {
        width: 100%;
    }
    .service-listing-bg .review-section .right-space-2 {
        margin-right: 30px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .featuring-badge img {
        margin: 0 40px 0 60px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .main-heading {
        width: 230px;
    }
  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
    .big-grey-btn {
        padding: 7px 24px !important;
        margin-bottom: 10px !important;
    }
    .host-order .cart-summary .booking-btn {
        padding: 10px 38px;
    }
    .top-section {
        padding-top: 30px;
    }
    .top-side-heading {
        margin-bottom: 0;
    }
    .service-listing-bg .service-logo img {
        height: 85px;
        width: 85px;
    }
    .host-order {
        padding: 20px;
    }
    .host-order .white-border {
        margin: 10px 0 20px 0;
    }
    .host-order span {
        margin-bottom: 10px;
    }
    .host-profile .grid-4 {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .host-profile .cart-summary {
        text-align: center;
    }
    .service-listing-bg .service-logo img {
        border: 5px solid #FF4110;
    }
    .host-order .fill-plus-icon {
        margin-bottom: 15px;
    }
    .host-order .beach-detail-grid {
        margin-bottom: 0;
    }
    .vertical-card-divider {
        margin: 20px 0;
    }
    .inner-main-heading h2 {
        margin-bottom: 0;
    }  
    .big-grey-btn {
        width: 100%;
    }
    .service-listing-bg .review-section .right-space-2 {
        margin-right: 50px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .main-heading {
        margin-right: 0px;
        width: 270px;
    }
    .service-listing-bg .horizontal-service-list-card .horizontal-service-listing .main-heading-block .featuring-badge img {
        margin: 0 151px 0 100px;
    }
    
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
  
}

@media (min-width: 989px) and (max-width: 992px) {
   
}