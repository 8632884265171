.heading-right {
    flex-shrink: 0;
    margin-left: 20px;

    .upload-right-space {
        margin-right: 22px;
    }
}

.top-heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.beach-detail-badge {
    background: color('primary');
    color: color('light');
    border-radius: 8px;
    padding: 4px 14px;
    font-size: 14px;

    .order-badge {
        font-size: 16px;
        font-weight: 400;
        color: color('light');
    }
}

.small-white-btn {
    // background: color(light);
    // border-color: color('light');
}

.view-all-btn-img {
    padding: 18px 30px !important;
    border-radius: 50px !important;
    position: absolute !important;
    bottom: 30px;
    right: 10px;
}

.beach-detail-grid {
    display: grid;
    grid-template-rows: repeat(4, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 16px;
    position: relative;
}

.row-span-4 {
    grid-row: span 4/ span 4;
}

.row-start-1 {
    grid-row-start: 1,
}

.row-span-2 {
    grid-row: span 2/ span 2;
}

.row-start-3 {
    grid-row-start: 3,
}

.d-grid {
    display: grid;
}

.border-right-radius {
    border-right: 1px solid color('light-sixth');
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.border-left-radius {
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.date-form {
    border-right: 1px solid color('light-sixth');
}

.bottom-space {
    margin-bottom: 20px !important;
}

.dotted-border-2 {
    border-bottom: 1px solid rgba(229, 228, 228, 0.6);
    margin: 20px 0;
}

.sub-total {
    display: flex;
    justify-content: space-between;

    .sub-text {
        font-weight: 400 !important;
    }
}

.total-amount {
    display: flex;
    justify-content: space-between;

    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        color: color('secondary');
    }
}

.total-order-btn {
    text-align: center;
    margin-top: 20px;
}

.order-btn {
    padding: 10px 20px !important;
    text-transform: capitalize !important;
    font-size: 14px;
}

.white-btn {
    text-transform: capitalize !important;
    color: color('secondary') !important;
    font-size: 18px;
    font-weight: 400;
    background: color(light) !important;
    border-color: color('light');
}

.grid-2 {
    grid-gap: 22px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.small-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #FF4110;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: auto;
    margin-right: 10px;
    flex-shrink: 0;

    img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}

.host-heading {
    font-size: 20px;
    font-weight: 400;
    color: color('secondary');
    margin-bottom: 0;
}

.host-img {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.host-listing .feature-listing li:not(:nth-child(-n+2)) {
    display: flex !important;
}

.right-heading {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.service-detail {
    .right-space {
        margin-right: 70px;
    }

    .main-heading {
        margin-right: 23px;

        span {
            font-size: 22px;
        }
    }

    .main-heading-block {
        justify-content: unset;
        margin-bottom: 5px;
        align-items: center;
    }

    .beach-detail-heading {
        margin-bottom: 0px;

        .feature-listing {
            display: flex;
            align-items: center;

            .pricing {
                font-size: 28px;
            }

            li:not(:nth-child(-n+2)) {
                display: flex;
            }

            li {
                width: unset;
                margin-right: 20px;
            }
        }
    }

    .heading-right-side-button {
        .profile-avatar {
            background: color('light-fourth');
            margin-right: 15px;
            height: 25px;
            width: 25px;

            img {
                width: 15px;
            }

        }
    }

    .small-text-heading {
        font-size: 16px;
        font-weight: 400;
    }

    .beach-detail-heading {
        display: flex;
        justify-content: space-between;

        .contact-btn {
            padding: 10px 16px;
            text-transform: capitalize;
            margin-left: 5px;
        }

    }

    .umberlla-img {
        img {
            height: 685px;
            width: 100%;
            object-fit: cover;
        }

    }

    .small-img {
        img {
            height: 333px;
            width: 100%;
            object-fit: cover;
        }

    }

    .img-btn {

        // position: relative;
        .small-white-btn {
            // position: absolute;
            // bottom:10px;
            // right: 10px;

        }

        // .white-btn{
        //     text-transform: capitalize !important;
        //     color: color('secondary') !important;
        //     font-size: 18px;
        //     font-weight: 400;
        // }
    }

    .umberlla-detail {
        .text-truncate-13line {
            display: -webkit-box;
            -webkit-line-clamp: 13;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .small-box {
        button {
            margin-right: 0;

            & :hover {
                background: none;
            }
        }

        .amount-added {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            p {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 400;
            }

        }

        .backgrond-remove {
            background: none;
        }

        .service-picker {
            img {
                width: 20px;
            }
        }
    }

    // .featuring-block{
    //     position: unset;
    //     .order-badge{
    //    position: unset;
    //     }
    // }
    .top-heading {
        .main-heading-block {
            .main-heading {
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }

}

.star-text {
    display: flex;
}

// .feature-listing li:not(:nth-child(-n+2)) {
//     display: none;
// }

.servicedetail_description {
    p {
        font-weight: 400;
    }
}


// ---responsive start------//
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .service-detail .main-heading {
        margin-right: 13px;
    }

    .heading-right {
        margin-left: 0;
        display: inline;
    }

    .service-detail .main-heading-block .main-heading {
        font-size: 25px;
    }
    .service-detail .top-heading {
        display: unset;
    }
    .service-detail .top-heading .main-heading-block {
        display: unset !important;
        flex-direction: column;
        align-items: flex-start;
    }

    .beach-detail-badge {
        border-radius: 8px;
        padding: 3px 6px;
        font-size: 7px;
    }

    .beach-detail-badge .order-badge {
        font-size: 13px;
        font-weight: 400;
        color: #fff;
    }

    .heading-right .upload-right-space {
        margin-right: 11px;
    }

    .service-detail .beach-detail-heading {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .service-detail .umberlla-img img {
        height: 250px;
        width: 100%;
    }

    .service-detail .small-img {
        display: none;
    }

    .view-all-btn-img {
        padding: 11px 14px !important;

    }

    .service-detail .beach-detail-heading .feature-listing .pricing {
        font-size: 20px;
    }

    .service-detail .beach-detail-heading .feature-listing li {
        //    margin-bottom: 15px;
        margin-right: 20px;

    }

    .beach-detail-heading .feature-listing li .feature-list-name span {
        font-size: 13px;
    }

    .service-detail .beach-detail-heading .contact-btn {
        margin-left: 0px;
    }

    .beach-detail-grid {
        margin-bottom: 0px;
    }

    .bottom-space {
        margin-bottom: 15px !important;
    }

    .dotted-border-2 {
        margin: 10px 0;
    }

    .total-order-btn {
        text-align: center;
    }

    .service-detail .beach-detail-heading .feature-listing {
        display: flex;
        flex-wrap: wrap;
    }

    .service-detail .heading-right-side-button .profile-avatar {
        display: block !important;
    }

    .heading-right-side-button {
        display: flex;
        flex-wrap: wrap;
        align-items: start;
    }

    // ---heading changes -----//
    .grid-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .right-heading {
        margin-left: 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
    }

    .host-listing .feature-listing {
        display: flex;
        flex-wrap: wrap;
    }

    .host-listing {
        width: 100%;
    }

    .host-listing .feature-listing li {
        flex-shrink: 0;
        width: 100%;
        padding-right: 25px;
    }

    .service-detail .top-heading .main-heading-block .main-heading {
        margin-bottom: 15px;
        display: inline;
        line-height: 30px;
    }

    // ---
    .section_hoursofoperation {
        width: 100% !important;
    }

    // .host-img {
    //     justify-content: center;
    // }
    // ---service-detailcontent ---//
    .servicedetail_description {
        width: unset !important;
    }

    .host-info-column {
        margin-top: 10px;
        border-top: 1px solid color('light-fourth');
        padding-top: 25px !important;
    }
    .detail-grid-image{
        height: 285px !important;
    }
}



// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .service-detail .top-heading .main-heading-block .main-heading {
        margin-bottom: 10px;
    }

    // .service-detail .beach-detail-heading .feature-listing li {
    //     margin-bottom: 15px;
    //  }
    .service-detail .beach-detail-heading {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .service-detail .umberlla-img img {
        height: 250px;
        width: 100%;
    }

    .service-detail .small-img {
        display: none;
    }

    .view-all-btn-img {
        padding: 11px 14px !important;

    }

    .service-detail .beach-detail-heading .feature-listing .pricing {
        font-size: 20px;
    }

    .service-detail .beach-detail-heading .feature-listing li {
        width: unset;
        margin-right: 20px;
    }

    .beach-detail-heading .feature-listing li .feature-list-name span {
        font-size: 13px;
    }

    .service-detail .beach-detail-heading .contact-btn {
        margin-left: 0px;
    }

    .beach-detail-grid {
        margin-bottom: 0px;
    }

    .bottom-space {
        margin-bottom: 15px !important;
    }

    .dotted-border-2 {
        margin: 10px 0;
    }

    .total-order-btn {
        text-align: center;
    }

    .service-detail .heading-right-side-button .profile-avatar {
        display: block !important;
    }

    // ---heading changes -----//
    .grid-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }

    .right-heading {
        margin-left: 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
    }

    .host-listing .feature-listing {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
    }

    .host-listing {
        width: 100%;
    }

    .host-listing .feature-listing li {
        flex-shrink: 0;
        width: unset;
        padding-right: 25px;
    }

    // ---service-detailcontent ---//
    .servicedetail_description {
        width: unset;
    }

    // ---service-detailcontent ---//
    .servicedetail_description {
        width: unset !important;
    }

    // .host-img {
    //     justify-content: center;
    // }

    .host-info-column {
        margin-top: 10px;
        border-top: 1px solid color('light-fourth');
        padding-top: 25px !important;
    }
    .detail-grid-image{
        height: 355px !important;
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .service-detail .beach-detail-heading {
        margin-bottom: 10px;
    }

    .service-detail .umberlla-img img {
        height: 400px;
    }

    .service-detail .small-img img {
        height: 191px;
    }

    .view-all-btn-img {
        padding: 11px 14px !important;
    }

    .service-detail .beach-detail-heading .feature-listing .pricing {
        font-size: 20px;
    }

    .service-detail .beach-detail-heading .feature-listing li {
        width: unset;
        margin-right: 20px;
    }

    .beach-detail-heading .feature-listing li .feature-list-name span {
        font-size: 13px;
    }

    .service-detail .beach-detail-heading .contact-btn {
        margin-left: 16px;
    }

    // ---heading changes -----//
    .grid-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .right-heading {
        margin-left: 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
    }

    .host-listing .feature-listing {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
    }

    .host-listing {
        width: 100%;
    }

    .host-listing .feature-listing li {
        flex-shrink: 0;
        width: unset;
        padding-right: 25px;
    }

    // ---service-detailcontent ---//
    .servicedetail_description {
        width: unset !important;
    }

    // .host-img {
    //     justify-content: center;
    // }
    .host-info-column {
        margin-top: 10px;
        border-top: 1px solid color('light-fourth');
        padding-top: 25px !important;
    }
    .detail-grid-image{
        height: 400px !important;
    }
}


/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .service-detail .beach-detail-heading {
        margin-bottom: 10px;
    }

    .service-detail .umberlla-img img {
        height: 605px;
    }

    .service-detail .small-img img {
        height: 293px;
    }

    .view-all-btn-img {
        padding: 15px 25px !important;
    }

}



/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

@media (min-width: 0) and (max-width: 899px) {
    .host-listing .feature-listing {
        display: inline-flex !important;
    }
}

.servicedetail_description_title {
    font-weight: 400 !important;
    margin: 30px 0px 15px !important
}

.servicedetail_description {
    margin: 15px 0 30px;
    // width: 750px;

    p {
        word-wrap: break-word;
        font-size: 16px;
    }
}

.section_hoursofoperation {
    width: 50%;

    .hoop_title {
        margin-bottom: 15px;
    }

    .hoop_content {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        margin-bottom: 15px;
        gap: 15px;

        .hoop_day {
            width: 210px;
        }

        .hoop_time {
            width: 150px;
            flex-shrink: 0;
            text-align: center;
        }
    }
}

.section_pricelist {
    margin: 15px 0;

    label {
        font-size: 16px;
        font-weight: 500;
        display:block;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid lightgrey;
        align-items: start;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    label:last-child {
        border: none;
        margin-bottom: 0;
    }

    .MuiRadio-root {
        padding-top: 1px;
        padding-left: 0;
    }

    .MuiFormControlLabel-label {
        font-weight: 500;
        font-size: 16px;
    }

    .priceset {
        margin-top: 10px;
        font-weight: 400;
        font-size: 20px;

        .listprice {
            color: grey;
            text-decoration: line-through;
        }
    }
}