.cart-item-value-each{
    font-size: 14px;
    line-height: 20px !important;
    display: inline-block;
    margin-bottom: 8px !important;
}
.cart-item-value{
    font-size: 24px;
    line-height: 30px !important;
}


 .booking-order{
    .heading-search{
        margin-bottom: 0;
        .main-heading-block{
            justify-content: unset;
            margin-bottom: 0;
        }
        .main-heading{
            margin-bottom: 0 !important;
            margin-right: 40px;
        }
    }
  
   
    .beach-detail-badge{
        .order-badge{
            font-size: 12px;
            line-height:23px;
        }
    }
    .pending-badge{
        display: inline-flex;
        align-items: center;
        .feature-listing {
            display: inline-flex;
            li{
                flex-shrink: 0;
                padding: 0;
                width: 150px;
            }
        }
        .beach-detail-badge{
            padding: 0 8px;
            margin-right: 18px;
        }
    }
    .top-small-btn{
        padding: 5px 24px;
        margin-right: 12px;
    }
    .right-side-block{
        .calendar-icon{
            margin-right: 10px;
        }
        margin-bottom: 20px;
    }
    .inner-page-medium-heading{
        font-size: 16px;
        font-weight: 400;
        margin-left: 25px;
    }
    .service-listing-bg{
        .main-heading-block{
            margin-bottom: 0;
        }
        justify-content: unset;
        .service-logo{
            img {
                height: 48px;
                width: 48px;
                border: 3px solid #FF4110;
                border-radius: 50%;
            }
        }
        .main-heading{
            font-size: 18px;
            margin-bottom: 0;
        } 
    } 
    .search-result-box-2{
        box-shadow:0px 0px 16px 0px rgb(9 6 15 / 8%);
        margin-bottom: 25px;
        .input-style{
            input {
                height: 32px;
            }
        }
    }
    .time-field{
        gap: 10px;
        display: flex;
        margin-bottom: 25px;
    }
    .heading-right-btn{
        display: flex;
        .select-style > div {
            height: 30px;
        }
    }
 }
 .card-detail{
    margin-bottom: 80px;
    img{
        height: 150px !important;
        width: 150px !important;
        object-fit: cover;
    }
    .cart-item-value-each{
        font-size: 14px;
        line-height: 20px !important;
        display: inline-block;
        margin-bottom: 8px !important;
    }
    .cart-item-value{
        font-size: 24px;
        line-height: 30px !important;
    }
    .cart-item-names{
        font-size: 18px;
        margin-bottom: 16px;
        display: block;
    }
    .total-input{
        font-size: 16px; 
    }
 }
 .host-info-listing{
     li{
        display: inline-flex;
        width: auto;
        padding: 0;
        &:not(:last-child){
            margin-right: 50px;
        }
        & > div{
            font-family: 'Jost';
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #808084;
        }
        img{
            opacity: .4;
        }
    }
 }
  // X-Small devices (portrait phones, less than 576px) /
  @include media-breakpoint-between(xs, sm) {
    .card-detail {
        margin-bottom: 40px;
    }
   .booking-order .service-listing-bg {
        margin-bottom: 30px;
    }
    .booking-order .service-listing-bg .main-heading {
        font-size: 18px !important;
        margin-bottom: 0;
    } 
    .card-detail img {
        height: 130px !important;
        width: 130px !important;
    }
    .booking-order .heading-right-btn .input-style {
        height: 35px;
    }
    .booking-order .heading-search {
        margin-bottom: 22px;
        flex-wrap: wrap;
    }
    .heading-right-btn button {
        width: 100%;
        margin-bottom: 10px;
    }
    .booking-order .heading-search .top-heading {
        margin-bottom: 15px;
    }
    .booking-order .heading-right-btn {
      width: 100%;
      flex-wrap: wrap;
    }
    .cart-item {
        padding-bottom: 25px;
        margin-bottom: 25px;
        gap: 15px;
        flex-wrap: wrap;
        padding: 15px;
        box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08) !important;
        border-radius: 15px;

        .cart-item-img {
            width: 70px !important;
            height: 70px !important;
        }

        .cart-item-name {
            font-size: 18px;
            margin-right: 30px;
        }
    }
    .booking-order .heading-search .main-heading-block {
        display: flex;
    }
    .booking-order .heading-right-btn {
        display: unset;
    }
    .booking-order .top-small-btn {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .booking-order .card-detail .cart-item-value-each {
        font-size: 14px;
        line-height: 20px !important;
        display: inline-block;
        margin-bottom: 0px !important;
    }
    .booking-order .time-field {
        gap:5px;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
    .booking-order .search-result-box-2 {
        box-shadow: 0px 0px 16px 0px rgba(9, 6, 15, 0.08);
        margin-bottom: 20px;
    }
    .mobile-show-scroll{
        display: flex;
        overflow-x: scroll;
        // white-space: nowrap;
    }
    .booking-order .pending-badge .feature-listing li {
        flex-shrink: 0;
        padding: 0;
        width: unset;
        padding-right: 25px;
    }
    .mobile-menu-host{
        display: flex;
        overflow-x: scroll;
    }
    .host-info-listing{
        li{
            &:not(:last-child){
                margin-right: 50px;
            } 
        }
    }
    .booking-order{
        .service-listing-bg{
            justify-content: unset;
            flex-direction: unset;
            gap: 10px;
            .service-logo{
                margin-right: 0;
                img{
                    height: 58px;
                    width: 58px;
                }
            }
        } 
    }
    .card-detail{
        .cart-item-value {
            font-size: 20px;
        }
    } 
    
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .card-detail {
        margin-bottom: 40px;
    }
   .booking-order .service-listing-bg {
        margin-bottom: 30px;
    }
    .booking-order .service-listing-bg .main-heading {
        font-size: 18px !important;
        margin-bottom: 0;
    } 
    .card-detail img {
        height: 130px !important;
        width: 130px !important;
    }
    .booking-order .heading-right-btn .input-style {
        height: 35px;
    }
    .booking-order .heading-search {
        margin-bottom: 22px;
        flex-wrap: wrap;
        align-items: start;
    }
    .heading-right-btn button{
        // width: 50%;
    }
    .booking-order .heading-search .top-heading {
        margin-bottom: 15px;
    }
    .booking-order .heading-right-btn {
      width: 100%;
    }
    .booking-order .search-result-box-2 .input-style {
        height: 35px;
    }

    .host-info-listing{
        li{
            &:not(:last-child){
                margin-right: 50px;
            } 
        }
    }
    .booking-order{
        .service-listing-bg{
            justify-content: unset;
            flex-direction: unset;
            gap: 10px;
            .service-logo{
                margin-right: 0;
                img{
                    height: 58px;
                    width: 58px;
                }
            }
        } 
    }
   
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .card-detail {
        margin-bottom: 50px;
    }
   .booking-order .service-listing-bg {
        margin-bottom: 30px;
    }
    .booking-order .service-listing-bg .main-heading {
        font-size: 18px !important;
        margin-bottom: 0;
    }
    .booking-order .heading-right-btn .input-style {
        height: 35px;
    }
    .booking-order .heading-search {
        flex-wrap: wrap;
    }
    // .booking-order .search-result-box-2 .input-style input{
    //         height: 30px;
    //     }
        .booking-order .search-result-box-2 .input-style {
            height: 35px;
        }

    .host-info-listing{
        li{
            &:not(:last-child){
                margin-right: 50px;
            } 
        }
    }
    
  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
  
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
     
}
