@use 'sass:map';

$colors: (
    primary: #FF4110,
    light-primary: #ffe6e0,
    secondary: #09060F,
    tertiary: #808084,
    dark-tertiary: #655C5C,
    light-tertiary: #E5E4E4,
    light-fourth: #F8F8FA,
    light-fifth: #D7D7D7,
    light-sixth: #EEEEEE,
    light-seven:#B1B1B1,
    light: #fff,
    light-opacity: rgba(255, 255, 255, 0.7),
    light-tertiary-opacity: rgba(229, 228, 228, 0.6),
    secondary-gradient: (0deg, rgba(9, 6, 15, 0.3), rgba(9, 6, 15, 0.3)),
    active-color:#D1822D,
    light-eight:#9E9EA1,
    );


$primaryFont: ('Jost', sans-serif);
