.border-color {
    border: 1px solid color('light-tertiary')
}

// .search-result-box {
//     padding: 8px 12px;

//     .sub-text {
//         font-size: 14px !important;
//         margin-bottom: 0 !important;
//         display: flex;
//         align-items: center;

//         img {
//             width: 16px;
//             height: 16px;
//             margin-right: 8px;
//         }

//     }

// }

.search-result-box-2 {
    padding: 8px 12px;

    .sub-text {
        font-size: 15px !important;
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

    }
}

.over-all-search {
    display: flex;
    gap: 20px;
    align-items: center;

    .input-style {
        input {
            height: 30px !important;
            padding: 0 8px 0px 25px;
        }
    }
}

.search-result-filter {
    background: color('light-fourth') !important;
    height: 36px;
    width: 36px;
    min-width: unset !important;
    padding: 0 !important;

    img {
        filter: brightness(0);
        height: 16px;
        width: 16px;
    }
}

.map-btn {
    padding: 20px 45px !important;
}

.search-result {
    .main-heading-block{
        margin-bottom: 0 !important;
    }
    padding-top: 40px;

    .search-result-heading {
        display: flex;
        justify-content: space-between;
    }

    .top-heading {
        .main-heading {
            margin-right: 12px;
        }
    }


}

.button-bottom {
    margin-bottom: 30px;
}

// ---modal popup ---//
.modal-popup {
    .modal-sub-title {
        font-size: 20px !important;
        font-weight: 400;
        margin-bottom: 20px;
        color: color('secondary');
    }

    .modalheader {
        padding: 30px 30px 0 30px !important;
        margin-bottom: 18px;
    }

    hr {
        margin-bottom: 0 !important;
    }

    .modalbody {
        padding: 30px;
        padding-bottom: 0;

        .input-style {
            input {
                height: 60px;
            }
        }

        .filter-form {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
        }

        .input-height {
            .input-style {
                input {
                    height: 30px;
                }
            }
        }

        .search-result-box-2 {
            box-shadow: 0px 0px 16px 0px #09060F14;
        }

        .Mui-focused::before {
            border-bottom: 0;
        }

        .grey-btn {
            color: color('secondary');
            display: flex;
            align-items: baseline;

            img {
                margin-left: 6px;
            }
        }

        .language-selection {
            display: flex;
            flex-wrap: wrap;
        }

        .register-check-box {
            width: 50%;
        }

        .language-selection>div {
            width: 50%;
        }

        .grey-btn &:hover {
            background-color: transparent;
        }

        .filter-menu-footer {
            padding: 32px 0;
        }

        .dotted-border {
            border-bottom: 1px solid color('light-tertiary');
            margin-bottom: 32px;
            padding-bottom: 32px;
        }


    }


}

.search-result-btn {
    display: none;
}

.search-result-filter {
    display: flex !important;
}

/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .modal-popup .modalbody .input-height .input-style {
        height: 30px;
    }

    .modal-popup .modal-sub-title {
        margin-bottom: 15px;
    }

    .modal-popup .modalbody .input-style {
        height: 50px;
    }

    .modal-popup .modalbody .filter-form {
        justify-content: space-between;
    }

    .modal-popup .modalbody .input-style input {
        height: 50px;
    }

    .map-btn {
        padding: 10px 25px !important;
        width: 100%;
    }

    .modal-popup .modalbody {
        padding: 20px 20px;
    }

    .modal-popup .modalheader {
        padding: 20px 20px 0 20px !important;
        margin-bottom: 10px;
    }

    .modal-popup .modalbody .search-result-box-2 {
        flex-wrap: wrap;
    }

    .modal-popup .border-right-radius {
        border-right: 0;
        padding-bottom: 15px;
    }

    .search-result .input-style:not(.input-style.end-date) {
        border-bottom: 0;
    }

    .search-result .search-result-heading {
        flex-wrap: wrap;
    }

    .search-result .main-heading-block {
        margin-bottom: 10px !important;
        display: flex;
    }

    .over-all-search {
        flex-wrap: wrap;
        justify-content: center;
    }

    .search-result .top-heading .main-heading {
        margin-bottom: 0;
    }

    .search-result-btn {
        display: block;
        width: 100%;
    }

    .search-result-filter {
        display: none !important;
    }

    .search-result .modal-filter-btn .filter-button {
        margin-top: 0 !important;
        margin-bottom: 15px;
        width: 100%;
    }

    .modal-popup .modalbody .dotted-border {
        margin-bottom: 25px;
        padding-bottom: 25px;
    }

    .modal-popup .modalbody .filter-menu-footer {
        padding: 20px 0;
        padding-bottom: 0;
    }
    .search-btn{
        width: 100%;
    }


}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .modal-popup .modalbody .input-height .input-style {
        height: 30px;
    }

    .modal-popup .modal-sub-title {
        margin-bottom: 15px;
    }

    .modal-popup .modalbody .input-style {
        height: 50px;
    }

    .modal-popup .modalbody .filter-form {
        justify-content: space-between;
    }

    .over-all-search .input-style {
        height: 30px !important;
    }

    // --------
    .over-all-search .input-style {
        height: 30px !important;
    }

    .search-result .search-listing-space .input-text-style>div {
        width: 250px;
    }

    .over-all-search {
        margin-bottom: 15px;
    }

    .search-result .search-result-heading {
        flex-wrap: wrap;
    }

    .search-result-btn {
        display: none;
    }

    .search-result-filter {
        display: flex !important;
    }

    .button-bottom {
        margin-bottom: 20px;
    }

    .modal-popup .modalbody .dotted-border {
        margin-bottom: 25px;
        padding-bottom: 25px;
    }

    .modal-popup .modalbody .filter-menu-footer {
        padding: 30px 0;
    }

}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .modal-popup .modalbody .input-height .input-style {
        height: 30px;
    }

    .modal-popup .modal-sub-title {
        margin-bottom: 15px;
    }

    .modal-popup .modalbody .input-style {
        height: 50px;
    }

    // --------
    .over-all-search .input-style {
        height: 30px !important;
    }

    .search-result .search-listing-space .input-text-style>div {
        width: 250px;
    }

    .over-all-search {
        margin-bottom: 15px;
    }

    .modal-popup .modalheader {
        padding: 25px 25px 0 25px !important;
        margin-bottom: 10px;
    }

    .modal-popup .modalbody {
        padding: 25px;
    }

    .modal-popup .modalbody .filter-menu-footer {
        padding: 25px 0;
        padding-bottom: 0;
    }


}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}