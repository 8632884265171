
  * {
    box-sizing: border-box;
  }
  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }
  
  body {
    line-height: 1; }
  
  ol, ul {
    list-style: none; }
  
  blockquote, q {
    quotes: none; }
  
  blockquote {
    &:before, &:after {
      content: '';
      content: none; } }
  
  q {
    &:before, &:after {
      content: '';
      content: none; } }
  
  table {
    border-collapse: collapse;
    border-spacing: 0; }

    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
   }  
   img {
      max-width: 100%;
      height: auto;
   } 
   a {
      text-decoration: none !important;
      outline: none !important;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
   }
   a:focus,
   a:hover {
      text-decoration: none;
   }
   .btn,
   .btn:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0);
   }
   button:focus {
      box-shadow: none !important;
   }
   .form-control:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
   }

   h1, .h1, h2, .h2, h3, .h3{
    margin-top: 0 !important;
   }

   .p-relative{
    position: relative;
   }

   .p-absolute{
    position: absolute;
   }

   .become-host-step-form .total-hours .opening-hours .sub-text {
        text-align: start;
    }

    .become-host-step-form .total-hours {
        display: flex;
        flex-wrap: wrap;
    }

    .become-host-step-form .total-hours .opening-hours {
        width: 200px;
        margin-bottom: 10px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show {
        display: flex;
        justify-content: unset;
    }

    .btn-inactive{
      background: white!important;
      border: 1px solid lightgray!important;
      color: black!important;
    }

    .faq-subheading{
      font-size: 22px;
      font-weight: bolder;
      margin: 10px 0 20px;
      border-bottom: 1px solid lightgrey;
      padding-bottom: 15px;
    }

.custom_breadcrumb{
  a{
    color: black;
    font-size: 15px;
  }

  li:last-child a{
    color: #a5a5a5;
  }

  p{
    font-size: 15px
  }
}