.mobile-link-btn {
    padding: 15px 0 !important;
    border-radius: 18px !important;
    width: 100%;
    font-size: 15px;
}

.mobile-bottom-btn {
    position: fixed;
    bottom: 0;
    padding: 20px;
    text-align: center;
    width: 100%;
    background: color('light');
    box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08);
    left: 0;
    z-index: 2;
    display: none;
}

.left-back-arrow {
    button{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        flex-shrink: 0;
        font-family: $primaryFont;
    }

    img {
        margin-right: 12px;
        background: color('light-fourth');
        border-radius: 12px;
        padding: 12px 8px;
    }

    .view-all-btn {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
}

.grid-4 {

    .vertical-card:hover:nth-child(4n+1):nth-last-child(-n+4),
    .vertical-card:nth-child(4n+1):nth-last-child(-n+4)~.vertical-card:hover {
        // height: 580px;
    }
}

.search-listing-space {
    .input-text-style {
        >div {
            border-radius: 50px;
            height: 50px;
            width: unset;
        }

        .MuiInputBase-input {
            padding-left: 0;
        }
    }
}

.fill-plus-icon {
    img {
        margin-right: 12px;
    }
}

// .service-top-header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding-bottom: 40px;
// }

.service-listing {
    padding-top: 40px;

    .service-listing-toggle {
        img {
            margin-right: 12px;
        }
    }

    // .left-side-menu-show {
    //     a:hover {
    //         background: none !important;
    //         color: color('primary') !important;

    //     }

    //     .listing-text {
    //         font-family: 'Jost';
    //         font-weight: 400;
    //         font-size: 16px;
    //         color: color('tertiary');

    //         &.active {
    //             color: color('primary');
    //         }
    //     }

    // }

    .grid-4 {
        grid-gap: 22px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.service-heading {
    .filter-sub-value {
        color: color('light-eight');
    }
}

.featuring-badge {
    .edit-bg {
        background: rgba(9, 6, 15, 0.5);
        padding: 5px;
        border-radius: 5px;
        margin-left: 8px;
    }
}

.featuring-block {
    .order-badge {
        border-radius: 3px !important;
        margin-left: 16px;
        padding: 6px 9px;
        margin-bottom: 0;
    }
}

.service-list-review {
    // display: flex;
    // justify-content: center;
    text-align: center;

    span {
        font-size: 16px;
        color: color('tertiary');
        margin-left: 10px;
        display: inline-block;
    }
}

.mobile-bottom-btn {
    display: none;
}

.mobile-menu-services {
    display: none;
}

.web-view-all {
    display: block;
}


// -----add info-----//
.grid-1 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .add-info-card {
        margin-bottom: 20px;
        border-radius: 12px;

        .image-bg-add-info {
            background: color('light-fourth') !important;
            margin-bottom: 20px;
            border-radius: 12px;
        }

        .add-info-card-title {
            font-size: 18px;
            color: color('secondary');
        }
    }

    .add-info-btn {
        background: color('primary');
        padding: 10px 25px;
        border-radius: 18px;
        font-size: 16px;

    }
}

.service-heading {
    h2 {
        font-size: 24px !important;
    }
}

.featuring-badge {
    img {
        margin-left: 5px;
    }

}

.service-header-block {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 15px;

    &>div {
        display: inline-flex;
        gap: 5px;
    }
}

// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .service-listing .grid-4 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .search-listing-space .input-text-style>div {
        width: unset;
    }



    .service-listing-toggle img {
        display: none;
    }

    .left-side-menu-show li {
        padding-right: 15px;
    }

    .left-side-menu-show li a {
        padding-left: 0 !important;
        color: color('tertiary');
    }

    .mobile-bottom-btn {
        position: fixed;
        bottom: 0;
        padding: 20px 30px;
        text-align: center;
        width: 100%;
        background: color('light');
        box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08);
        left: 0;
        z-index: 3;
    }

    .fill-plus-icon img {
        display: none;
    }

    .mobile-bottom-btn {
        display: block;
    }

    // .fill-plus-icon {
    //     display: none;
    // }

    .mobile-menu-services {
        display: none;
    }

    // .filter-sub-value {
    //     display: none;
    // }

    // .web-view-all {
    //     display: none;
    // }

    .mobile-menu-services {
        display: block;
    }

    .mobile-menu-services .main-heading-block {
        display: flex;
        margin-bottom: 0;
    }

    .service-list-left-side {
        margin-bottom: 20px;
    }

    // ---add card info---//
    .grid-1 .add-info-card {
        width: 100%;
    }

    .grid-1 .add-info-card .image-bg-add-info {
        height: 320px;
    }

    .grid-1 .add-info-btn {
        padding: 10px 60px;
    }

    .left-back-arrow {
        font-size: 16px;
    }

    .service-heading h2 {
        line-height: 22px !important;
    }

    .service-listing {
        .main-heading-block {
            display: flex;
            gap: 10px;

            .service-header-block {
                button {
                    padding: 6px 10px;
                }
            }
        }
    }
    .service-listing .search-listing-space{
        width: 60% !important;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .service-listing .grid-4 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .search-listing-space .input-text-style>div {
        width: unset;
    }

    .service-listing-toggle img {
        display: none;
    }

    .left-side-menu-show li a {
        padding-left: 0 !important;
        color: color('tertiary');
    }

    .fill-plus-icon img {
        display: none;
    }

    .mobile-bottom-btn {
        display: block;
    }

    // .fill-plus-icon {
    //     display: none;
    // } 

    .service-listing {
        padding-top: 20px;
    }

    // .filter-sub-value {
    //     display: none;
    // }

    .mobile-menu-services {
        display: none;
    }

    // .web-view-all {
    //     display: none;
    // }

    .mobile-menu-services {
        display: block;
    }

    .mobile-menu-services .main-heading-block {
        margin-bottom: 0;
    }

    .service-list-left-side {
        margin-bottom: 20px;
    }

    // ------add info----//
    .grid-1 .add-info-card .image-bg-add-info {
        height: 200px;
    }

    .left-back-arrow {
        font-size: 18px;
    }

    .service-heading h2 {
        line-height: 22px !important;
    }

    // .left-side-menu-show li {
    //     padding-right: 15px;
    // }

}

// Medium devices (tablets, 768px and up) /
@include media-breakpoint-between(md, lg) {
    .service-listing .grid-4 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .search-listing-space .input-text-style>div {
        width: 330px;
    }

    .mobile-bottom-btn {
        display: none;
    }

    // .service-listing-toggle img {
    //     display: none;
    // }

    .service-list-left-side {
        margin-bottom: 10px;
    }

    // .service-list-left-side ul li {
    //     width: auto !important;
    //     padding-right: 15px;
    // }

    .left-side-menu-show li a:not(:first-child) {
        padding-left: 0 !important;
    }
}

// Large devices (desktops, 992px and up) /
@include media-breakpoint-between(lg, xl) {
    .service-listing .grid-4 {
        grid-gap: 5px;
    }
}

// Extra large devices (large desktops, 1200px and up) /
@include media-breakpoint-between(xl, xxl) {}


@media (min-width: 0) and (max-width: 899.98px) {
    .left-side-menu-show {
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
        gap: 20px;

        li {
            width: auto;

            a {
                font-family: 'Jost';
                font-weight: 400;
                font-size: 16px;
                color: color('tertiary');
                padding: 0;
                &.service-listing-toggle, .service-listing-toggle{
                  border-bottom: 2px solid color('light');
                  margin: 0 0 5px 0;
                  padding: 5px 0; 
                  &.active {
                    color: color('primary');
                    border-bottom: 2px solid color('primary');
                    }
                }
                span{
                    padding: 0;
                }
            }
            a:hover {
                background: none !important;
                color: color('primary') !important;
            }
        }
    }

    .service-listing .service-listing-toggle img {
        display: none;
    }
}