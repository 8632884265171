.faq-section {
    background-color: color("light-fourth");
    .modal-back-btn {
      width: 48px;
      height: 48px;
      background-color: color("light");
      &:hover {
        background-color: color("light");
      }
    }
    .main-heading-block {
      gap: 32px;
    }
  }
  .vertical-tab-block {
    gap: 40px;
    .MuiTabs-indicator {
      display: none;
    }
    .tab-support-button {
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
  }
  .tab-block {
    background-color: color("light");
    width: 360px;
    flex-shrink: 0;
    padding: 20px 30px;
    .faq-question {
      align-items: flex-start;
      border-bottom: 1px solid color("light-sixth") !important;
    }
    .input-style {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .question-block {
    border: 0 !important;
    // border-bottom: 1px solid color("light-sixth") !important;
    .faq-question {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      border-right: 0;
      text-transform: unset !important;
      color: color("tertiary") !important;
      padding-left: 0;
      padding-right: 0;
      text-align: start;
      &.Mui-selected {
        color: color("secondary") !important;
      }
    }
  }
  .faq-value-block {
    background-color: color("light");
    width: 100%;
  }
  .faq-answer {
    padding: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    .faq-answer-heading {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
  .faq-accordion {
    .question-block {
      .faq-question {
        padding: 0;
        &.Mui-expanded {
          color: color("secondary") !important;
        }
        .MuiAccordionSummary-content {
          margin: 20px 0;
        }
      }
    }
    button{
      margin-top: 15px;
    }
  }
  // X-Small devices (portrait phones, less than 576px) /
  @include media-breakpoint-between(xs, sm) {
    .faq-section {
      background-color: color("light");
      padding: 20px;
      border-radius: 20px;
      .modal-back-btn {
        width: 38px;
        height: 38px;
        background-color: color("light-fourth");
        &:hover {
          background-color: color("light-fourth");
        }
      }
      .main-heading-block {
        display: flex;
        gap: 15px;
        margin-bottom: 0;
        .main-heading {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .faq-answer {
      font-size: 16px;
      line-height: 25px;
      padding: 0 !important;
    }
    .faq-accordion {
      .question-block {
        .faq-question {
          .MuiAccordionSummary-content {
            margin: 15px 0;
          }
        }
      }
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .faq-section {
      background-color: color("light");
      padding: 20px;
      border-radius: 20px;
      .modal-back-btn {
        width: 38px;
        height: 38px;
        background-color: color("light-fourth");
        &:hover {
          background-color: color("light-fourth");
        }
      }
      .main-heading-block {
        gap: 20px;
        margin-bottom: 0;
        .main-heading {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .faq-answer {
      font-size: 16px;
      line-height: 25px;
      padding: 0 !important;
    }
    .faq-accordion {
      .question-block {
        .faq-question {
          .MuiAccordionSummary-content {
            margin: 15px 0;
          }
        }
      }
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .faq-section {
      background-color: color("light");
      padding: 20px;
      border-radius: 20px;
      .modal-back-btn {
        width: 38px;
        height: 38px;
        background-color: color("light-fourth");
        &:hover {
          background-color: color("light-fourth");
        }
      }
      .main-heading-block {
        margin-bottom: 0;
      }
    }
    .faq-accordion {
      .question-block {
        .faq-question {
          .MuiAccordionSummary-content {
            margin: 15px 0;
          }
        }
      }
      .mobile-bottom-btn{
        display: block;
      }
    }
    .faq-answer {
      padding: 0 !important;
    }
  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
    .tab-block {
      padding: 20px 25px;
    }
    .faq-answer {
      padding: 30px 25px;
    }
    .vertical-tab-block {
      gap: 30px;
    }
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
  }