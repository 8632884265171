    textarea{
        padding: 15px 20px;
        height: 165px;
        &:focus-visible{
            outline: 0;
        }
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: color('secondary');
            opacity: 1;
        }
    
        &::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: color('secondary');
        }
    
        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: color('secondary');
        }
    }
    .send-btn {
        button {
            width: 100%;
        }
    }
    .message-modal{
        text-align: center;
        img{
            margin-bottom: 20px;
        }
        h2{
            font-size: 24px;
            line-height: 34px;
            color: color('secondary');
            margin-bottom: 20px;

        }
        p{
            font-size: 16px;
            line-height: 22px;
            color: color('tertiary');
            margin-bottom: 20px;
        }

        .time-field p{
            margin-bottom: 0px;
        }
    }
    .on-way-modal{
        margin-bottom: 10px;
        .time-field{
            input{
                background-color: transparent;
            }
        }
    }
    .MuiSwitch-track{
        background-color: #eeeeee !important;
    }
    .Mui-checked + .MuiSwitch-track{
        background-color: color('primary') !important;
    }
    .modal-body.message-modal {
        padding-left: 0;
        padding-right: 0;
    }
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .message-modal{
        img {
            margin-bottom: 15px;
        }
        h2 {
            font-size: 22px;
            margin-bottom: 15px;
            line-height: 30px;
        } 
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {

}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
   
}

  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
     
}

