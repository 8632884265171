.section-border{
    border-bottom: 1px solid color('light-fourth');
}
.main-heading-bottom{
    margin-bottom: 28px !important;
}
.small-box{
    background: color('light');
    box-shadow: 0px 0px 24px rgba(9, 6, 15, 0.05);
    border-radius: 24px;
    padding: 30px;
    h2{
        font-size: 18px;
        margin-bottom:30px;
        font-weight: 400;
    }
    .feature-list-name{
        span{
            padding-bottom: 28px;
        }
    } 
    .feature-listing-icon{
        margin-top:0 !important; 
    }
    button{
        margin-right: 18px;
    }
}
.show-more{
    text-align: center;
    .grey-btn{
        background:color('light-fourth') ;
        padding: 8px 18px !important;
        border-radius: 30px !important;
        color: color('secondary') !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        text-transform:capitalize !important;
        text-align: center;
        img{
            margin-left: 6px !important;
        }
    }  
}
.beach-detail-heading{
    margin-bottom: 0px;
    .main-heading{
        margin-right: 50px;
    }
    justify-content: unset;
    .feature-listing{
        li {
            padding:0;
            align-items: flex-start;
            .feature-list-name{
                span {
                    font-size: 16px;
                }
            } 
        }
    }
}
.beach-detail-slider{
    margin-bottom:76px;
    &.vertical-card{
        .card-media-item {
            padding: 0 !important;
            position: unset !important;
            box-shadow: none !important;
            .card-image {
                height: 615px;
            }
        }
        Button{
            display: inline-flex;
        }
    } 
}
.beach-detail{
    .beach-detail-bottom-space{
        margin-bottom: 40px;
    }
}
.about-beach{
    padding-top: 40px;
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
    a{
        text-decoration: underline !important;
        color:color('secondary');
        &:hover{
            color:color('secondary');
        }
    }
    .contact-btn{
        padding: 10px 50px;
        font-size: 14px;
        text-transform: capitalize;
    }
    .box-contact-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  

}
// .beach-detail-slider{
//     position: unset !important;
// }
.right-space{
    margin-right: 100px;
}
.text-truncate-7line{
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.opacity-40{
    opacity: 40%;
}

.pe-none{
    pointer-events: none;
}

.cursor-pointer{
    cursor: pointer;
}
// .text-truncate-7line-hidde{
//     display: -webkit-box;
//     -webkit-line-clamp: unset;
//     -webkit-box-orient: vertical;  
//     overflow: hidden;
// }



// ---responsive start------//
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .main-heading-bottom {
        margin-bottom: 15px !important;
    }
    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 300px;
    }
    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 250px;
    }
    .main-heading-bottom {
        margin-bottom: 10px !important;
    }
    .beach-detail-slider {
        margin-bottom: 20px;
    }
    .right-space {
        margin-right: 0 !important;
    }
    .beach-detail-heading .main-heading {
        margin-right: 20px;
    }
    .beach-detail .beach-detail-bottom-space {
        margin-bottom: 30px;
    }
    .right-space {
        margin-bottom: 25px;
    }
    .service-listing {
        padding-top: 20px !important;
    }
}



// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .main-heading-bottom {
        margin-bottom: 15px !important;
    }
    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 300px;
    }
    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 250px;
    }
    .main-heading-bottom {
        margin-bottom: 10px !important;
    }
    .beach-detail-slider {
        margin-bottom: 20px;
    }
    .right-space {
        margin-right: 0 !important;
        margin-bottom: 25px;
    }
    .beach-detail-heading .main-heading {
        margin-right: 20px;
    }
    .beach-detail .beach-detail-bottom-space {
        margin-bottom: 30px;
    }
   
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {

    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 300px;
    }
    .main-heading-bottom {
        margin-bottom: 10px !important;
    }
    .beach-detail-slider {
        margin-bottom: 30px;
    }
    .right-space {
        margin-right: 0 !important;
    }
}


/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .beach-detail-heading {
        margin-bottom: 0;
    }
    .right-space {
        margin-right: 0 !important;
    }
    .beach-detail-slider.vertical-card .card-media-item .card-image {
        height: 405px;
    }
    .beach-detail-slider {
        margin-bottom: 25px;
    }
    .main-heading-bottom {
        margin-bottom: 0px !important;
    }
  
}



/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {
  
}

