.otp-input {
    margin: 30px 0;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      input {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        background-color: color("light");
        border: 1px solid color("dark-tertiary");
        text-align: center;
        font-size: 30px;
        font-family: $primaryFont;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .modal-heading-back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .modal-back-btn {
    padding: 0 !important;
    background: color("light-fourth");
    width: 36px;
    height: 36px;
    min-width: unset !important;
    border-radius: 12px !important;
    flex-shrink: 0;
  }
  .otp-code-msg {
    display: flex;
    align-items: center;
    gap: 7px;
    img {
      flex-shrink: 0;
    }
  }
  // X-Small devices (portrait phones, less than 576px) /
  @include media-breakpoint-between(xs, sm) {
    .otp-input {
      margin: 20px 0;
      & > div {
        gap: 3px;
        input {
          font-size: 20px;
          width: 40px;
          height: 40px;
        }
      }
    }
    .otp-code-msg {
      justify-content: center;
    }
    .modal-body {
      text-align: center;
    }
    .modal-heading-back {
      display: block;
    }
    .modal-back-btn {
      margin-bottom: 15px;
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .otp-input {
      margin: 20px 0;
      & > div {
        input {
          font-size: 25px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  // Medium devices (tablets, 768px and up) /
  @include media-breakpoint-between(md, lg) {
    .otp-input {
      margin: 20px 0;
      & > div {
        input {
          font-size: 26px;
          width: 53px;
          height: 53px;
        }
      }
    }
  }
  // Large devices (desktops, 992px and up) /
  @include media-breakpoint-between(lg, xl) {
  }
  // Extra large devices (large desktops, 1200px and up) /
  @include media-breakpoint-between(xl, xxl) {
  }