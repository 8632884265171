.search-order {
    .modalbody {
        .filter-form {
            display: flex;
            align-items: start;
            gap: 30px;

            .beachgear-value-input {
                display: flex;
                align-items: start;
                gap: 0;
                flex-direction: column;

                p {
                    color: color('secondary');
                }
            }
        }

        .search-order-popup {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

        }
    }

    .time-field {
        display: flex;
        gap: 15px;
    }

    .search-result-box-2 {
        margin-bottom: 10px;
    }

    .order-date-box {
        padding: 15px;

        .input-style {
            input {
                height: 40px;
            }
        }

    }
}
.search-order{
    .modalbody{
        padding-bottom: 0;
        .search-result-box-2{
            .input-style{
                height: 30px !important;
            }
        } 
    } 
} 


// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .search-order .search-result-box-2 {
        margin-bottom: 20px;
    }

    .order-date-box .input-style {
        height: 40px;
    }

    .search-order .modalbody .filter-form {
        gap: 15px;
    }

    .search-order .modalbody .search-order-popup {
        gap: 15px;
    }

    .search-order .time-field {
        gap: 0;
        flex-wrap: wrap;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .search-order .search-result-box-2 {
        margin-bottom: 20px;
    }

    .order-date-box .input-style {
        height: 40px;
    }

    .search-order .modalbody .filter-form {
        gap: 15px;
    }
}

// Medium devices (tablets, 768px and up) /
@include media-breakpoint-between(md, lg) {
    .search-order .search-result-box-2 {
        margin-bottom: 20px;
    }

    .order-date-box .input-style {
        height: 40px;
    }
}

// Large devices (desktops, 992px and up) /
@include media-breakpoint-between(lg, xl) {}

// Extra large devices (large desktops, 1200px and up) /
@include media-breakpoint-between(xl, xxl) {}


.pickupdeliverymessage {
    h6 {
        font-size: 16px;
        margin: 15px 0 10px;
    }

    button {
        padding: 15px 30px;
        margin-right: 10px;
    }

}