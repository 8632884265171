.black-underline-link{
    font-size: 1.7rem;
    color: black !important;
    font-weight: 500 !important;
    text-decoration: underline !important;
}
.black-link{
    font-size: 1.7rem;
    color: black !important;
    font-weight: 500 !important;
}