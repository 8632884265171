.text-small-modal  {
  font-size: 15px !important;
  font-weight: 400;
  margin-bottom: 20px;
  color: #09060F;
}
.MuiModal-root div:nth-child(3):focus-visible{
  outline: 0 !important;
}

.section-padding {
  padding-bottom: 80px;
}

.section-padding-y {
  padding: 80px 0;
}

.inner-section-padding {
  padding: 40px 0;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rounded-button {
  border-radius: 50rem !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.border-radius-md {
  border-radius: 18px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.border-0 {
  border: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: color("light") !important;
}

.bg-light-fourth {
  background-color: color("light-fourth") !important;
}

.shadow-none {
  box-shadow: none !important;
}

.btn-primary {
  background-color: color("primary") !important;
  border: 1px solid color("primary") !important;
  color: color("light") !important;
}

.btn-secondary {
  background-color: color("secondary") !important;
  border: 1px solid color("secondary") !important;
  color: color("light") !important;
}

.btn-secondary-outline {
  background-color: transparent !important;
  border: 1px solid color("light-tertiary") !important;
  color: color("tertiary") !important;
}

.btn-tertiary {
  background-color: color("tertiary") !important;
  border: 1px solid color("tertiary") !important;
  color: color("light") !important;
}

.link-primary {
  color: color("primary") !important;
}

.link-secondary {
  color: color("secondary") !important;
}

.text-underline {
  text-decoration: underline !important;
}

.primary-text {
  color: color("primary") !important;
}

.secondary-text {
  color: color("secondary") !important;
}

.tertiary-text {
  color: color("tertiary") !important;
}

.light-eight-text {
  color: color("light-eight") !important;

}

.light-text {
  color: color("light") !important;
}

.mobile-bottom-btn {
  position: fixed;
  bottom: 0;
  padding: 20px;
  text-align: center;
  width: 100%;
  background: color("light");
  box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08);
  left: 0;
  z-index: 2;
  display: none;

  .mobile-link-btn {
    padding: 15px 0 !important;
    border-radius: 18px !important;
  }

  .border-radius-12 {
    border-radius: 12px !important;
  }

  .border-radius-30 {
    border-radius: 30px !important;
  }

  .border-radius-60 {
    border-radius: 60px !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-white {
    background-color: color("light") !important;
  }

  .bg-light-fourth {
    background-color: color("light-fourth") !important;
  }

  .bg-light-sixth {
    background-color: color("light-sixth") !important;
  }

  .shadow-none {
    box-shadow: none !important;
  }

  .btn-primary {
    background-color: color("primary") !important;
    border-color: color("primary") !important;
    color: color("light") !important;
  }

  .btn-secondary-outline {
    background-color: transparent !important;
    border-color: color("light-tertiary") !important;
    color: color("tertiary") !important;
  }

  .link-primary {
    color: color("primary") !important;
  }

  .link-secondary {
    color: color("secondary") !important;
  }

  .text-underline {
    text-decoration: underline !important;
  }

  .primary-text {
    color: color("primary") !important;
  }

  .secondary-text {
    color: color("secondary") !important;
  }

  .tertiary-text {
    color: color("tertiary") !important;
  }

  .mobile-bottom-btn {
    position: fixed;
    bottom: 0;
    padding: 20px;
    text-align: center;
    width: 100%;
    font-size: 15px;
  }
}

.textfield-rouded-md {
  >div {
    border-radius: 18px;
  }

}

input[type='password'] {
  font-family: Verdana;
  letter-spacing: 0.125em;
}

button {
  text-transform: capitalize !important;
}

button {
  & :hover {
    background-color: none !important;
  }

}

.img-color {
  filter: brightness(0);
}

.black-text {
  span {
    color: color('secondary') !important;
  }
}

.text-case {
  text-transform: capitalize !important;
}

.border-radius-60 {
  >div {
    border-radius: 60px !important;
  }
}

.object-cover {
  object-fit: cover;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  border-radius: 5px 30px 30px 5px;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

input:focus-visible {
  outline: none;
}

.cc-btn-width {
  min-width: 140px;
  padding: 10px !important;
  margin-bottom: 0px !important
}

.outline-btn-white {
  background-color: transparent !important;
  border: 1px solid color('secondary') !important;
  padding: 6px 24px !important;
  color: color('secondary') !important;
  text-transform: capitalize !important;
}

// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
  .section-padding {
    padding-bottom: 20px;
  }

  .section-padding-y {
    padding: 40px 0;
  }

  .mobile-bottom-btn {
    display: block;
  }
  .cc-btn-width {
    width: 100% !important;
    margin-bottom:10px !important;
  }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
  .section-padding {
    padding-bottom: 40px;
  }

  .section-padding-y {
    padding: 40px 0;
  }

  .mobile-bottom-btn {
    display: block;
  }
 
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
  .section-padding {
    padding-bottom: 50px;
  }

  .section-padding-y {
    padding: 50px 0;
  }
  .cc-btn-width {
    min-width: 110px;
  }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
  .section-padding {
    padding-bottom: 60px;
  }

  .section-padding-y {
    padding: 60px 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

.trip-badge {
  padding: 0px 11px;
  border-radius: 20px;
  font-size: 15px;
  color: color('light');

  &.pending {
    background: #808084;
  }

  &.inprogress {
    background: #1ba070;
  }

  &.upcoming {
    background: #d1832d;
  }

  &.completed {
    background: #d8d8de;
    color: #09060f;
  }

  &.cancelled {
    background: #FF4110;
  }

  &.denied {
    background: #FF4110;
  }
}

.small-btn {
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.secondary-btn {
  background: color('light-fourth') !important;
  color: color('secondary') !important;
}

.outline-btn {
  background-color: transparent !important;
  border: 1px solid color('light-tertiary') !important;
  padding: 6px 24px !important;
  color: color('tertiary') !important;
  text-transform: capitalize !important;
}

.medium-shadowed-sidebar {
  box-shadow: 0px 0px 16px rgba(9, 6, 15, 0.08) !important;
}

.medium-shadow {
  box-shadow: 0px 0px 16px rgba(9, 6, 15, 0.08) !important;
}

// card title start
.popular-beachgear-section .card-title-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
}

// card title end

.black-text {
  span {
    color: color('secondary') !important;
  }
}

// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
  .section-padding {
    padding-bottom: 40px;
  }

  .section-padding-y {
    padding: 40px 0;
  }

  .border-radius-12 {
    border-radius: 12px !important;
  }

  .border-radius-30 {
    border-radius: 30px !important;
  }

  .border-0 {
    border: 0 !important;
  }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
  .section-padding {
    padding-bottom: 40px;
  }

  .section-padding-y {
    padding: 40px 0;
  }

  .mobile-bottom-btn {
    display: block;
  }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
  .section-padding {
    padding-bottom: 50px;
  }

  .section-padding-y {
    padding: 50px 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
  .section-padding {
    padding-bottom: 60px;
  }

  .section-padding-y {
    padding: 60px 0;
  }

  .inner-section-padding {
    padding: 20px 0;
  }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
  .section-padding {
    padding-bottom: 40px;
  }

  .section-padding-y {
    padding: 40px 0;
  }

  .mobile-bottom-btn {
    display: block;
  }

  .inner-section-padding {
    padding: 30px 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
  .section-padding {
    padding-bottom: 50px;
  }

  .section-padding-y {
    padding: 50px 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
  .section-padding {
    padding-bottom: 60px;
  }

  .section-padding-y {
    padding: 60px 0;
  }

  // card title start
  .popular-beachgear-section .card-title-truncate {
    width: 100px;
  }

  // card title end
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.swal2-popup {
  font-size: 15px !important;
}

.MuiCalendarPicker-root .Mui-selected {
  background-color: color("primary") !important;
}

.react_lightgallery_item img {
  display: none !important;
}

.text-center {
  text-align: center !important;
}

.personal-info .css-27y2fm-MuiGrid2-root {
  padding: 0 !important;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: not-allowed !important;
  background-color: #8e9296 !important;
  border-color: #8e9296 !important;
  color: #efefef !important;
  opacity: 0.55;
}

.qty-input {
  margin: 0;
  width: 30px;
  justify-content: center;
  text-align: center;
}

.fz-2 {
  font-size: 1.3rem !important;

  .MuiTypography-root {
    font-size: 1.3rem !important;
  }
}

.MuiTypography-body1 {
  font-family: 'Jost', sans-serif !important;
}

.site-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.detail-grid-1 {
  width: 60% !important;
}

.detail-grid-2 {
  width: 40% !important;
  padding-left: 30px !important;
}

@include media-breakpoint-between(xs, sm) {
  .detail-grid-1 {
    width: 100% !important;

  }

  .detail-grid-2 {
    padding-left: 0px !important;
    width: 100% !important;
  }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
  .detail-grid-1 {
    width: 100% !important;

  }

  .detail-grid-2 {
    padding-left: 0px !important;
    width: 100% !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
  .detail-grid-1 {
    width: 100% !important;

  }

  .detail-grid-2 {
    padding-left: 0px !important;
    width: 100% !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

.home-location.pac-container {
  margin-left: -40px;
  width: 220px !important;
}

.home-location .pac-item {
  padding: 4px 5px;
}

.home-location .hdpi.pac-logo:after {
  display: none !important;
}

.swal2-container {
  top: 95px !important;
  left: 20px !important;
}

.swal2-container.swal2-center {
  top: 0 !important;
  left: 0 !important;
}

.datepicker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as desired */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.booking-calender .MuiPickersArrowSwitcher-button{
  padding: 12px !important;
}

.next-btn {
  display: none !important;
}

.prev-btn {
  display: none !important;
}

.delete-title-swal{
  font-size : 21px !important;
}

.serviceListTitle{
  word-wrap: "break-word";
  line-height: 26px;
}