.sub-text {
    color: color('light-seven');
    font-size: 16px !important;

    a {
        text-decoration: underline !important;
    }
}

.modalheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .modal-close-btn{
        margin-left: auto;
        Button {
            position: absolute;
            top: 24px;
            right: 12px;
            z-index: 1;
            &:hover{
                background-color: transparent !important;
            }
        }
    } 
}

.modal-heading {
    font-size: 24px;
    font-weight: 400;
    color: color('secondary');
    line-height: 27px;
    width: 90%;
}

.modal-body {
    .input-style {
        border: 1px solid color('light-sixth');
        // margin-bottom: 20px;
    }

    .field-helper-text {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    .field-helper-text.error {
        color: red !important;
    }

    .outline-input:hover::before,
    .outline-input::after {
        border-bottom: 0px solid !important;
    }

    .header-submit-btn {
        background-color: color('primary');
        width: 100% !important;
        padding: 20px 0;
        border-radius: 50px;
        color: color('light');
        font-size: 16px;

        &:hover {
            background-color: color('primary');
            color: color('light');
        }
    }


}

.modalfooter {
    .title {
        font-size: 16px;
        color: color('light-seven');
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 20px 0;
    }

    .title--center-line {
        text-align: center;

        &::before {
            content: "";
            background-color: rgba(#000, .2);
            height: 1px;
            width: 100%;
            margin-right: 1rem;
        }

        &::after {
            content: "";
            background-color: rgba(#000, .2);
            height: 1px;
            width: 100%;
            margin-left: 1rem;
        }
    }

    .social-media-btn {
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
    }

    Button {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: color('secondary');
        padding: 14px;
        border: 1px solid color('light-sixth');
        border-radius: 50px;
        text-transform: capitalize;
        min-width: 205px;

        & img {
            margin-right: 12px;
        }
    }
}
.login-form{
    padding: 15px 0 0 !important;
    a:focus{
        color: color('primary');
    }
}

/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .modalfooter .social-media-btn {
        flex-wrap: wrap;
    }

    .modalfooter Button {
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .modallogin .modal-body .header-submit-btn {
        padding: 15px 0;
        margin-bottom: 17px;
    }

    .modalfooter .title {
        margin: 20px 0;
    }

    .modalfooter .social-media-btn {
        margin-bottom: 14px;
    }
    
    .modalheader .modal-close-btn Button {
        top: 18px;
        right: -4px;
    }
     // -----captcha ----//
 .g-recaptcha iframe{
    transform:scale(0.77) ;
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .modalfooter Button {
        padding: 10px;
        width: 100%;
    }

    .modallogin .modal-body .header-submit-btn {
        padding: 15px 0;
        margin-bottom: 17px;
    }

    .modalfooter .title {
        margin: 20px 0;
    }

    .modalfooter .social-media-btn {
        margin-bottom: 14px;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .modalheader .modal-close-btn Button {
        top: 20px;
        right: 0;
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .modalfooter .title {
        margin: 25px 0;
    }

    .modalfooter .social-media-btn {
        flex-wrap: wrap;
    }

    .modalfooter Button {
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .modalheader .modal-close-btn Button {
        top: 29px;
    }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .modalfooter .title {
        margin: 30px 0;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}