
.right-drawer{
    border-radius: 40px 0px 0px 40px;
    .drawer-image{
        // img{
        //     height: 360px;
        //     width: 483px;
        //     object-fit: cover;
        //     border-radius: 40px 0px 0px 40px;
        // }
    }
    .drawer-inner{
        padding: 25px 40px;
       .about-inner-heading{
        margin-bottom: 0;
       } 
       .vertical-card-divider{
        margin: 20px 0;
       }
       .cart-summary{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                display: flex;
                width: 50%;
                padding: 0;
                margin-bottom: 22px;
                span{
                    font-size: 14px;
                }
            }
        }
       }
       .description-text{
        font-size: 18px;
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
       }
       .drawer-content{
        display: block;
        margin-bottom: 100px;
        font-weight: 400;
       }
       .filter-menu-footer{
        padding: 30px 0 0 ;
        h2{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            font-size: 16px;
            font-weight: 500;
        }
        p{
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
        }
         
       }
       .filter-menu-footer{
        .save-btn{
            margin-left: 0 !important;
            height: unset;
            width: unset;
            padding: 7px 25px;
        }
    } 
    .inner-main-heading{
        img{
            height: 34px;
        }
        .drawer-total-amount{
            font-weight:500 ;
        }
    }
    .footer-btn-quantity-btn{
        display: flex;
        align-items: center;
        button{
            min-width: 65px;
        }
    }

    }
    .drawer-image{
        position: relative;
        // margin-bottom:76px;
        &.vertical-card{
            .card-media-item {
                padding: 0 !important;
                position: unset !important;
                box-shadow: none !important;
                .card-image {
                    height: 360px;
                    border-radius: 0 !important;
                }
            }
            Button{
                display: none;
            }
        } 
        .close-btn{
            display: block !important;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }
    }
  
   
}
 // X-Small devices (portrait phones, less than 576px) /
 @include media-breakpoint-between(xs, sm) {
    .right-drawer .drawer-inner {
        padding: 25px 20px;
    }
    .right-drawer .drawer-inner .cart-summary ul li {
        margin-bottom: 10px;
    }
    .right-drawer .drawer-inner .description-text {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .drawer-content {
        margin-bottom: 50px;
    }
    .right-drawer .drawer-image.vertical-card .card-media-item .card-image {
        height: 200px;
    }
    .right-drawer .drawer-inner .filter-menu-footer {
        padding: 20px 0 0;
    }
    .right-drawer .drawer-inner .filter-menu-footer .save-btn {
        padding: 7px 12px;
    }
    .right-drawer .drawer-inner .filter-menu-footer {
        flex-wrap: wrap;
    }
    .right-drawer .drawer-inner .filter-menu-footer h2 {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .filter-menu-footer .save-btn {
        width: 100%;
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .right-drawer .drawer-inner {
        padding: 25px 20px;
    }
    .right-drawer .drawer-inner .cart-summary ul li {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .description-text {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .drawer-content {
        margin-bottom: 60px;
    }
    .right-drawer .drawer-image.vertical-card .card-media-item .card-image {
        height: 200px;
    }
    .right-drawer .drawer-inner .filter-menu-footer {
        padding: 20px 0 0;
    }
    .right-drawer .drawer-inner .filter-menu-footer {
        flex-wrap: wrap;
    }
    .right-drawer .drawer-inner .filter-menu-footer h2 {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .filter-menu-footer .save-btn {
        width: 100%;
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .right-drawer .drawer-inner {
        padding: 25px 30px;
    }
    .right-drawer .drawer-inner .cart-summary ul li {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .description-text {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .drawer-content {
        margin-bottom: 70px;
    }
    .right-drawer .drawer-image.vertical-card .card-media-item .card-image {
        height: 260px;
    }
    .right-drawer .drawer-inner .filter-menu-footer {
        flex-wrap: wrap;
    }
    .right-drawer .drawer-inner .filter-menu-footer h2 {
        margin-bottom: 15px;
    }
    .right-drawer .drawer-inner .filter-menu-footer .save-btn {
        width: 100%;
    }

  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
   
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
     
}
