.big-heading{
    background-color: color("light-fourth");
    .help-big-heading{
        font-size: 42px;
        font-weight: 400;
    }
}                      
.help-block-page{
    background-color: color("light-fourth");
    padding-top: 40px;
   .horizontal-tab{
    button{
        font-size: 20px;
        padding: 0;
        text-transform: capitalize;
        color: color('tertiary');
        font-weight: 400;
    }
    button:not(:last-child){
        margin-right:50px;
    }
    .Mui-selected{
        color: color('secondary');
        border-bottom: 1px solid color('primary');
    }
    span{
        background: none;
    }
   

   }
  & [role="tabpanel"]{
    > div{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
  }
}

ul.tips-list {
    list-style: none;
    font-size: 20px;
    padding-left: 20px;
    a {
        color: color("primary") !important;
    }

    li{
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    li::before {
        content: " ";
        background-color: color("primary");
        font-weight: bold;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50rem;
        margin-left: -1em;
        margin-right: 15px;
    }
}
.coming-soon-text{
    font-size: 30px;
    font-weight: 500;
    color: color('primary');
}
   // X-Small devices (portrait phones, less than 576px) /
   @include media-breakpoint-between(xs, sm) {
    .big-heading .help-big-heading {
        font-size: 25px;
    }
     .help-block-page .horizontal-tab button:not(:last-child) {
        margin-right: 30px;
    }
     .help-block-page .horizontal-tab button {
         font-size: 18px;
     }
     .help-block-page {
         padding-top: 20px;
     }
     ul.tips-list li {
        font-size: 18px;
        line-height: 27px;
     }
     
   }
 
     // Small devices (landscape phones, 576px and up) /
     @include media-breakpoint-between(sm, md) {
        .big-heading .help-big-heading {
            font-size: 29px;
        }
         .help-block-page .horizontal-tab button:not(:last-child) {
            margin-right: 35px;
        }
         .help-block-page .horizontal-tab button {
             font-size: 19px;
         }
         .help-block-page {
             padding-top: 25px;
         }
     }
       // Medium devices (tablets, 768px and up) /
   @include media-breakpoint-between(md, lg) {
    .big-heading .help-big-heading {
        font-size: 32px;
    }
     .help-block-page .horizontal-tab button:not(:last-child) {
        margin-right: 40px;
    }
     .help-block-page .horizontal-tab button {
         font-size: 19px;
     }
     .help-block-page {
         padding-top: 30px;
     }
   }
     // Large devices (desktops, 992px and up) /
     @include media-breakpoint-between(lg, xl) {
         .big-heading .help-big-heading {
             font-size: 35px;
         }
         .help-block-page .horizontal-tab button:not(:last-child) {
             margin-right: 45px;
         }
         .help-block-page .horizontal-tab button {
             font-size: 19px;
         }
         .help-block-page {
             padding-top: 35px;
         }
     }
     // Extra large devices (large desktops, 1200px and up) /
     @include media-breakpoint-between(xl, xxl) {
 
     }
