.header-section {
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
}

.swal2-container {
    z-index: 9999 !important;
}

.location-input {
    .Mui-disabled {
        -webkit-text-fill-color: black !important;
    }
}

.beach-gear-search {
    .Mui-disabled {
        -webkit-text-fill-color: black !important;
    }
}

.input-style {
    &.error {
        border: 1px red solid !important;
    }
}

.field-helper-text.error {
    color: red !important;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background-color: color('light');
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header-menu {
    button {
        &:not(.profile-settings button) {
            width: 48px;
            height: 48px;
        }

        svg {
            font-size: 20px;
        }

        &:hover {
            background-color: transparent;
        }

        &.active {
            background-color: color('primary');

            svg {
                color: color('light');
            }
        }
    }

    .header-icons {
        &.active {
            img {
                filter: brightness(0) invert(1);
            }

            .notification-badge>span {
                background-color: color('light');
                color: color('secondary');
            }

            .notification-dot>span {
                // background-color: color('tertiary');
                box-shadow: 0px 0px 4px rgb(9 6 15 / 18%);
            }
        }


        &.cart-icon {
            span>span {
                background-color: color('primary');
                color: color('light');
                box-shadow: 0px 0px 4px rgba(9, 6, 15, 0.18);
                /* width: 17px; */
                height: 17px;
                border-radius: 20px;
                min-width: 17px;
                font-weight: 400;
                font-size: 11px;
                top: -7px;
                padding: 1px 3px 3px 4px;
                right: -4px;
            }
        }
    }

    .notification-dot {
        &>span {
            background-color: color('primary');
            transform: scale(1) translate(80%, -80%);
        }
    }

    .profile-image {
        img {
            height: 100%;
            object-fit: cover;
            filter: none !important;
            height: 100%;
            object-fit: cover;
        }
    }

    .profile-settings {
        background-color: color('light-fourth');
        border-radius: 50px;

        button {
            padding: 8px 10px;
            width: 75px;
            height: 48px;
            border-radius: 30px;

            .notification-dot {
                gap: 12px;
            }

            &.active {
                .profile-avatar {
                    img {
                        filter: brightness(0);
                    }
                }

            }
        }

        .profile-avatar {
            background-color: color('light');
            width: 32px;
            height: 32px;
            margin-left: 10px;
        }
    }

    &> :not(last-child) {
        margin-left: 10px !important;
    }
}

.header-login-btn {
    background-color: color('primary') !important;
    width: 100px !important;
    padding: 20px 0;
    border-radius: 50px !important;
    color: color('light') !important;
    font-size: 16px;
    margin-bottom: 20px;

    &:hover {
        background-color: color('primary') !important;
        color: color('light') !important;
    }
}

.bottom-border {
    border-bottom: 1px solid color('light-fourth');
}

.menu-popper {
    z-index: 4;
    width: 300px;

    .menu-dropdown {
        margin-top: 12px;
        border-radius: 16px;
        box-shadow: 0px 0px 20px 0px rgba(9, 6, 15, 0.1);

        .menu-heading {
            font-size: 18px !important;
            color: color('secondary');
        }
    }
}

.header-menu-list {
    width: 300px;
}

.profile-popper {
    width: 210px;
}

.profile-box {
    margin-top: 12px !important;
    min-width: 210px;
    padding: 22px 0 !important;

    a {
        li {
            margin-bottom: 12px !important;
        }
    }

    li {
        font-family: $primaryFont !important;
        font-size: 14px;
        font-weight: 400;
        color: color('tertiary');
        position: relative;
        padding: 0 !important;
        padding: 0 16px !important;
        min-height: auto;

        &:hover {
            background-color: transparent !important;
        }

        &.active {
            font-size: 16px;
            color: color('secondary') !important;

            &:before {
                content: '';
                position: relative;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: color('active-color');
                top: 0;
                left: 0;
                margin-right: 5px;
            }
        }

        img {
            margin-right: 10px;
        }

        &.profile-name {
            &hover {
                background-color: transparent !important;
            }

            .profile-img-bg {
                background-color: color('light-fourth') !important;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px !important;
            }

            img {
                margin-right: 0 !important;
                object-fit: cover;
                height: 100%;
            }

        }

        h2 {
            font-size: 18px !important;
            word-break: break-all;
            white-space: break-spaces;
            margin-bottom: 0;
        }
    }

    li:not(:last-child) {
        margin-bottom: 12px;
    }
}


.profile-space {
    min-width: unset !important;

}

.profile-name {
    font-size: 18px;
    color: color('secondary') !important;
    font-weight: 400;
}

hr {
    margin-top: 4px !important;
    margin-bottom: 16px !important;
}


.product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 12px;
}

.cart-info-block {
    font-size: 16px;
    width: 100px;
    margin-right: 10px;

    .cart-title {
        color: color('secondary');
        display: block;
        margin-bottom: 7px;
        margin-bottom: 8px;
        line-height: 24px;
    }

    button {
        padding: 0;
        text-transform: capitalize;
        color: color('tertiary');

        img {
            margin-right: 5px;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

.quantity-block {
    width: 83px;

    button {
        min-width: unset !important;
    }

    span:not(.count-badge, .count-badge > *) {
        font-size: 16px;
        color: color('tertiary');
        display: block;
        text-align: end;
        padding-top: 10px;
    }

    .count-badge {
        &>span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: color('secondary');
        }
    }

    p {
        margin-bottom: 0;
    }
}

.cart-menu {
    .menu-heading {
        padding: 16px 20px 5px 20px;
    }

    li {
        padding: 16px 20px;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid color('light-fourth');

        .cart-menu-list {
            display: flex;
            align-items: center;
        }
    }

    &>li {
        &:nth-last-child(-n + 2) {
            border-bottom: 0;
        }

    }

    .checkout-btn {
        padding-top: 0 !important;
    }
}

.cart-scrollbar {

    // height: 285px !important;
    li {
        width: 300px;
    }
}

.cart-summary {
    width: 100%;

    .summary-price {
        color: color('tertiary');
        padding-bottom: 10px;

        &>span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px;
            color: color('tertiary') !important;
        }

        &.summary-price-total {
            padding-top: 8px;
            padding-bottom: 0;

            span {
                font-size: 18px;
                color: color('secondary');
            }
        }
    }
}

.menu-btn {
    height: 32px;
    font-size: 14px !important;
    text-transform: unset !important;
}

.notification-list {
    .menu-scrollbar {

        // height: 540px !important;
        &>div {
            margin-bottom: 0 !important;
        }
    }

    .menu-heading {
        padding: 16px;
    }

    .notification-menu {
        li {
            padding: 16px 16px;
            border-bottom: 1px solid color('light-fourth');
            position: relative;

            &.unread-notification {
                &:before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: color('primary');
                    top: 26px;
                }

                .notification-message {
                    padding-left: 15px;
                }
            }

            // &:first-child {
            //     margin-top: -16px;
            // }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.notification-message {
    &>* {
        white-space: break-spaces;
    }

    span {
        font-size: 12px;
        color: color('tertiary');
    }

    h6 {
        margin-top: 5px;
        color: color('secondary');
        font-size: 14px;
    }

    .notification-info {
        font-size: 14px;
    }
}

.notification-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px 12px;

    &>button {
        width: 50%;
    }
}

.notification-menu-footer {
    padding: 12px;
    text-align: center;
    box-shadow: 0px -20px 10px rgb(9 6 15 / 2%);

    a {
        font-size: 14px;
    }
}

// -----search filter button---------//
.search-filter-btn {
    padding: 25px 30px 0 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    a {
        min-width: 96px;
        padding: 6px 13px;
        flex-shrink: 0;
    }

    :not(:last-child) {
        margin-right: 10px;
    }
}

.logo img {
    width: 160px;
}

/* Responsive styles */
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {

    .header-menu {
        .profile-settings {
            background-color: transparent;
            border-radius: unset;

            button {
                width: 24px;
                height: 24px;
                padding: 0;

            }
        }

        button {
            &:not(.profile-settings button) {
                width: 38px;
                height: 38px;
            }
        }

        .notification-dot {
            &>span {
                transform: scale(1) translate(100%, -100%);
            }
        }
    }

    .profile-avatar {
        display: none !important;
    }

    // .notification-list {
    //     .menu-scrollbar {
    //         padding-bottom: 20px;
    //     }
    // }

    .profile-box {
        padding: 20px 0 !important;
    }

    .menu-popper {
        width: 100%;
        padding: 0 16px !important;
    }

    .header-menu-list {
        width: unset;
    }

    .cart-info-block {
        width: 80px;
        margin-right: 5px;

        button {
            &:not(.profile-settings button) {
                width: 30px;
                height: 30px;
            }

            img {
                width: 21px;
                height: 21px;
            }
        }
    }

    .profile-avatar {
        display: none !important;
    }

    .header-section {
        .rental-search-form {
            display: none;
        }
    }

    .cart-menu li {
        padding: 16px 15px;
    }

    .cart-scrollbar {
        li {
            width: calc(100% - 17px) !important;
        }
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {

    .header-menu {
        .profile-settings {
            background-color: transparent;
            border-radius: unset;

            button {
                width: 45px;
                height: 45px;
                padding: 0;

            }
        }

        button {
            &:not(.profile-settings button) {
                width: 35px;
                height: 35px;
            }

            img {
                width: 22px;
                height: 22px;
            }
        }

        &> :not(last-child) {
            margin-left: 30px !important;
        }

        .notification-dot {
            &>span {
                transform: scale(1) translate(100%, -100%);
            }
        }
    }

    .profile-avatar {
        display: none !important;
    }

    // .notification-list {
    //     .menu-scrollbar {
    //         padding-bottom: 20px;
    //     }
    // }

    .profile-box {
        padding: 20px 0 !important;
    }

    .header-section {
        .rental-search-form {
            display: none;
        }
    }

    .cart-scrollbar {
        li {
            width: calc(100% - 17px) !important;
        }
    }

}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    // .notification-list {
    //     .menu-scrollbar {
    //         padding-bottom: 20px;
    //     }
    // }

    .header-section {
        .rental-search-form {
            display: none;
        }
    }

    .cart-scrollbar {
        li {
            width: calc(100% - 17px) !important;
        }
    }

}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .header-menu {
        &> :not(last-child) {
            margin-left: 5px !important;
        }
    }

    .logo {
        img {
            width: 130px;
        }
    }

    // .notification-list {
    //     .menu-scrollbar {
    //         padding-bottom: 20px;
    //     }
    // }
    .header-menu button:not(.profile-settings button) {
        margin-left: 0 !important;
        width: 45px;
        height: 45px;
    }

    .cart-scrollbar {
        li {
            width: calc(100% - 17px) !important;
        }
    }

}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}

@media (min-width: 320px) and (max-width: 374.98px) {
    .category-card {
        .card-image {
            height: 125px;
            width: 125px;
        }

        .card-title {
            width: 125px;
        }
    }
}

.profile-avatar {
    border-radius: 50px;
}

.w-100 {
    width: 100% !important;
}

.pac-container {
    z-index: 9999999999 !important;
}