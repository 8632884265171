.user-image {
    border-radius: 50% !important;
    width: 128px !important;
    height: 128px !important;
    object-fit: cover;
    margin-bottom: 10px !important;
}

.user-info-card {
    border: 1px solid #DDDDDD !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    padding: 24px !important;
    text-align: center;
}

.link-update {
    color: black !important;
    text-decoration: underline !important;
    display: block;
    cursor: pointer;
    font-size: 1.6rem;
    margin-top: 10px !important;
}

.confirmed-section {
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: #EBEBEB !important;
    margin-top: 20px;
    padding-top: 20px;
}

.reviews-borders {
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: #EBEBEB !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #EBEBEB !important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.disable-link {
    opacity: 30%;
    pointer-events: none;
}