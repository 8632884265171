.radio-check-button {
    .MuiFormControlLabel-label {
        color: color('tertiary') !important;
    }

    .Mui-checked+span {
        color: color('secondary') !important;
    }
}

.sub-text {
    font-size: 16px !important;
    font-weight: 400 !important;
}


.become-host-step-form {
    .main-heading-block {
        .main-heading {
            font-weight: 400;
            font-size: 36px;
            color: coloe('secondary');
            line-height: 44px;
        }
    }

    .small-heading {
        h2 {
            font-size: 25px;
            font-weight: 400;
            color: color('secondary');
            line-height: 32px;
        }
    }

    .main-heading-block {
        margin-bottom: 0px;
        display: unset;
    }

    .small-radius {
        border-radius: 18px;
    }

    .Mui-disabled {
        color: color('tertiary');
        font-size: 20px;
        font-weight: 400;
    }

    .Mui-active {
        font-size: 20px;
        color: color('secondary');
        // line-height: 20px;
    }

    .stepper-bottom {
        margin-bottom: 30px;
    }

    .textfield-area {
        height: 170px !important;
        background: color('light');
        border: 1px solid color('light-tertiary');
        border-radius: 18px;
        padding: 18px;
        font-size: 16px;
        font-weight: 400;

        &:focus-visible {
            outline: 1px solid color('light-tertiary') !important;
        }

        &::placeholder {
            color: color('secondary');
            font-family: 'Jost';
            font-weight: 400;
            font-size: 16px;
        }
    }

    .total-hours {
        display: flex;
        gap: 12px;

        .opening-hours {
            width: 240px;
            margin-bottom: 20px;

            .sub-text {
                margin-bottom: 10px;
                color: color('tertiary');
                font-size: 16px;
            }

            .hours-show {
                display: flex;
                justify-content: space-around;
                border: 1px solid color('light-tertiary');
                border-radius: 18px;

                .MuiSelect-iconOutlined {
                    display: none;
                }

                .MuiInputBase-formControl {
                    border-right: 1px solid color('light-tertiary');
                }

                .border-none-class {
                    .MuiInputBase-formControl {
                        border-right: none;
                    }
                }

                div {
                    border-right: none;
                    margin: 0;

                    .input-style {
                        margin: 0 !important;
                        padding: 0;
                    }

                  
                }

                .MuiOutlinedInput-notchedOutline {
                    border: transparent !important;
                    color: color('secondary')
                }

                .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                    padding: 15px;
                }

            }


        }
    }

    .date-selection-btn {
        height: 34px;
        width: 34px;
        border-radius: 50% !important;
        border: 1px solid color('light-tertiary');
        margin-right: 10px;
        font-weight: 400;
        color: color('tertiary');
        background: transparent;
        padding: 5px;

        &.Mui-selected {
            background-color: color('primary');
            color: color('light');
            font-weight: 400;
            border: 1px solid color('primary');
        }

        p {
            text-transform: capitalize;
            font-size: 12px !important;
        }

    }

    .sub-text {
        & a {
            font-size: 16px;
            font-weight: 400;
            color: color('tertiary') !important;
            text-transform: capitalize;
            margin-left: 12px;
        }
    }

    .languages {
        display: unset !important;
    }

    .add-your-language {
        display: flex;

        .language-selection {
            width: 250px;
        }
    }

    .multiple-date-select {
        display: flex;
        justify-content: start;
        align-items: center;

        .date-selection-btn {
            height: 34px;
            width: 34px;
            border-radius: 50% !important;
            border: 1px solid color('light-tertiary');
            margin-right: 10px;
            font-weight: 400;
            color: color('tertiary');
            background: transparent;
            padding: 5px;

            &.Mui-selected {
                background-color: color('primary');
                color: color('light');
                font-weight: 400;
                border: 1px solid color('primary');
            }

            p {
                text-transform: capitalize;
                font-size: 12px !important;
            }

        }

        .sub-text {
            & a {
                font-size: 16px;
                font-weight: 400;
                color: color('tertiary') !important;
                text-transform: capitalize;
                margin-left: 12px;
            }
        }
    }

    .Mui-completed {
        font-size: 20px;
        color: color('secondary');
        box-shadow: none;
    }

   
    .bottom-btn {
        margin-bottom: 50px;
    }

    .grid-space {
        margin-bottom: 30px;
    }

   
}




.become-host-form {
    margin-bottom: 0;

    .modal-close-btn {
        Button {
            margin-left: 0 auto;
        }

    }

    .radio-check-button {
        .MuiFormControlLabel-label {
            color: color('tertiary') !important;
        }
    }
}

.upload-text {
    font-size: 16px;
    font-weight: 400 !important;
    color: color('tertiary');
    margin-left: 0 !important;
}
 .upload-image-icon {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column !important;
        border: 1px dotted color('light-tertiary');
        border-radius: 18px;
        padding: 60px 40px;
        margin-bottom: 30px;
    }
   


// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .become-host-step-form .stepper-bottom {
        margin-bottom: 30px;
    }

    .become-host-step-form .main-heading-block {
        margin-bottom: 0 !important;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 13px;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 20px;
        margin-bottom: 5px !important;
        text-align: start;
        line-height: 23px;
    }

    .become-host-step-form .small-heading h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        text-align: start;
    }

    .become-host-step-form .input-style .input-image {
        padding-left: 0px;
    }

    .become-host-step-form .Mui-active {
        font-size: 16px !important;
        line-height: 19px;
    }

    .become-host-step-form .Mui-disabled {
        font-size: 16px !important;
        line-height: 19px;
        border-radius: 18px;
    }

    .become-host-step-form .css-1evo22s-MuiStepLabel-label {
        margin-top: 15px;
        display: none;
    }

    .radio-check-button {
        margin-bottom: 10px;
        text-align: start;
    }

    .languages {
        margin-bottom: 10px !important;
    }

    .grid-space {
        padding: 0 !important;
        margin-bottom: 15px;
    }

    .become-host-step-form .textfield-area {
        height: 150px !important;
    }

    .become-host-step-form .total-hours .opening-hours .sub-text {
        text-align: start;
    }

    .become-host-step-form .total-hours {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .become-host-step-form .total-hours .opening-hours {
        width: 100%;
        margin-bottom: 10px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show {
        display: flex;
        justify-content: unset;
    }

    .become-host-step-form .multiple-date-select .date-selection-btn {
        height: 30px;
        width: 30px;
        margin-right: 10px;
        padding: 5px;
    }
    .input-style .input-image {
        padding-left: 15px;
        width: unset;
    }
       // ----modal padding ---//
       .become-host-step-form{
        padding: 15px 0 !important;
    }
    .become-host-step-form .stepper-bottom .MuiStepLabel-labelContainer {
        display: none;
    }
    .support-service-check-box{
        .sub-text{
            text-align: start;
        }
    }
    .become-host-step-form .grid-space {
        margin-bottom: 20px;
    }
    .become-host-form .modalheader .modal-close-btn Button {
        top: 4px;
        right: -4px;
    }
    .company-hours-switch{
        text-align: right;
    }
    .company-hours-days{
        text-align: left;
        margin: auto 0;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .become-host-step-form .stepper-bottom {
        margin-bottom: 30px;
    }

    .become-host-step-form .main-heading-block {
        margin-bottom: 0 !important;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 13px;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 25px;
        margin-bottom: 15px !important;
    }

    .become-host-step-form .small-heading h2 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .become-host-step-form .input-style .input-image {
        padding-left: 0px;
    }

    .become-host-step-form .Mui-active {
        font-size: 16px !important;
        line-height: 20px;
    }

    .become-host-step-form .Mui-disabled {
        font-size: 16px !important;
        line-height: 20px;
        border-radius: 18px;
    }

    .become-host-step-form .css-1evo22s-MuiStepLabel-label {
        margin-top: 15px;
    }

    .radio-check-button.become-host-step-form {
        margin-bottom: 20px;
    }

    .languages {
        margin-bottom: 20px !important;
    }

    .grid-space {
        margin-bottom: 20px;
    }
      // ----modal padding ---//
      .become-host-step-form{
        padding: 15px 0 !important;
    }
    .become-host-step-form .Mui-completed {
        font-size: 16px;
        line-height: 20px;
    }
    .become-host-step-form .grid-space {
        padding: 4px;
    }
    .company-hours-switch{
        text-align: right;
    }
    .company-hours-days{
        margin: auto 0;
    }
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .become-host-step-form .stepper-bottom {
        margin-bottom: 30px;
    }

    .become-host-step-form .main-heading-block {
        margin-bottom: 10px;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 13px;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 0px;
    }

    .become-host-step-form .small-heading h2 {
        font-size: 23px;
    }

    .become-host-step-form .input-style .input-image {
        padding-left: 0px;
    }

    .become-host-step-form .Mui-active {
        font-size: 18px !important;
        line-height: 20px;
    }

    .become-host-step-form .Mui-disabled {
        font-size: 18px !important;
        border-radius: 18px;
        top: 0px;
        line-height: 20px;
    }
     // ----modal padding ---//
     .become-host-step-form{
        padding: 15px 0 !important;
    }
    .become-host-step-form .Mui-completed {
        font-size: 18px;
        line-height: 20px;
    }
    .become-host-step-form .Mui-disabled {
        font-size: 18px;
    }
    .time-switch{
        & + div{
            p{
                font-size: 14px;
            }
        }
    }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
    .become-host-step-form .stepper-bottom {
        margin-bottom: 30px;
    }

    .become-host-step-form .main-heading-block {
        margin-bottom: 0;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    .become-host-step-form .total-hours .opening-hours .hours-show .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 13px;
    }

    .become-host-step-form .main-heading-block .main-heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 0px;
    }

    .become-host-step-form .small-heading h2 {
        font-size: 25px;
    }
    // ----modal padding ---//
    .become-host-step-form{
        padding: 15px 0 !important;
    }
    // .become-host-step-form .Mui-active {
    //     font-size: 19px;
    // }
    .become-host-step-form .Mui-completed {
        font-size: 19px;
    }

}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}