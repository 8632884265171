.host-btn{
    padding: 6px 30px !important;
}
.notification-section{
    .main-heading-block{
        margin-bottom: 10px;
        .main-heading {
            display: flex;
            align-items: center;
        }
        .left-back-arrow{
            img {
                padding: 18px 12px;
            }
        } 
    }
    .notification-card{
        &.notification-position{
            &::before{
                position: unset;
            }
          
        }
        position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: color('primary');
                    top: -6px;
                    left: -6px;
                }
        margin-bottom: 20px;
        padding: 20px 30px;
        border-radius: 16px;
        .notification-header{
            display: flex;
            justify-content: space-between;
            margin-bottom:5px;
        }
        .inner-main-heading{
            h3{
                margin-bottom: 0;
                a{
                font-size: 24px;
                font-weight: 400;
                color: color('primary');
                }
            }
            .notification-list {
                position: relative;
                li{
                    margin-bottom: 15px;
                    margin-left: 25px;
                    font-size: 24px;
                    font-weight: 400;
                    padding: 0 !important;
                    line-height: 36px;
                    &::before{
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: color('tertiary');
                        top: 15px;
                        left: -11px;
                    }
                    }
                   
                }
               
            }
        }
    } 
    .notification-message{
        position: relative;
        span{
            margin-left:14px;
            &::before{
                content: "";
                position: absolute;
                width: 8px;
                height:8px;
                border-radius: 50%;
                background-color: color('primary');
                top:7px;
                left: 0px;
            }
        }
    a{
        color: color('primary');
    }
        
    }

    .notification-read{
        span{
            margin-left:0;
            &::before{
                content: unset;
            }
        }
    }
    
    // Responsive Styles start
// X-Small devices (portrait phones, less than 576px) /
@include media-breakpoint-between(xs, sm) {
    .notification-section .notification-card::before {
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
    }
    .notification-section .notification-card .inner-main-heading .notification-list li {
        margin-bottom: 5px;
        margin-left: 15px;
        font-size: 20px;
        line-height: 30px;
    }
    .notification-section .notification-card .inner-main-heading h2 a {
        font-size: 20px;
    }
    .notification-section .inner-main-heading h3 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px !important;
    }
    .notification-section .notification-card .inner-main-heading h3 a {
        font-size: 18px;
    }
    .notification-section .notification-card {
        margin-bottom: 20px;
        padding: 12px 12px;
    }
    .notification-section .main-heading-block .left-back-arrow img {
        padding: 15px 8px;
    }
    .host-btn {
        width: 100%;
    }
    .notification-section .notification-card .inner-main-heading .notification-list {
        margin-top: 10px;
    }
}

// Small devices (landscape phones, 576px and up) /
@include media-breakpoint-between(sm, md) {
    .notification-section .notification-card::before {
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
    }
    .notification-section .notification-card .inner-main-heading .notification-list li {
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 20px;
        line-height: 30px;
    }
    .notification-section .notification-card .inner-main-heading h2 a {
        font-size: 22px;
    }
    .notification-section .inner-main-heading h3 {
        font-size: 18px;
        line-height: 29px;
        margin-bottom: 10px !important;
    }
    .notification-section .notification-card .inner-main-heading h3 a {
        font-size: 18px;
    }
    .notification-section .notification-card {
        margin-bottom: 20px;
        padding: 17px 30px;
    }
    .notification-section .main-heading-block .left-back-arrow img {
        padding: 15px 8px;
    }
  
}

/* // Medium devices (tablets, 768px and up) */
@include media-breakpoint-between(md, lg) {
    .notification-section .notification-card::before {
        width: 15px;
        height: 15px;
        top: -3px;
        left: -3px;
    }
    .notification-section .notification-card .inner-main-heading .notification-list li {
        margin-bottom: 10px;
        margin-left: 20px;
        font-size: 22px;
        line-height: 26px;
    }
    .notification-section .notification-card .inner-main-heading h3 a {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .notification-section .inner-main-heading h3 {
        font-size: 22px;
    }
    .notification-section .inner-main-heading h3 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px !important;
    }
    .notification-section .notification-card .inner-main-heading .notification-list {
        margin-top: 10px;
    }
}

/* // Large devices (desktops, 992px and up) */
@include media-breakpoint-between(lg, xl) {
   
}

/* // Extra large devices (large desktops, 1200px and up) */
@include media-breakpoint-between(xl, xxl) {}
