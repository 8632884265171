.inner-page-small-heading{
    h6{
        font-size: 16px;
        font-weight: 400;
        color:color('secondary');
        margin-bottom: 40px;
    }
    span{
        // margin-left: 10px;
        display: inline-block;
    }
    .host-heading{
        font-size: 16px;
    }
    .sub-text{
        margin: 0 10px;
    }
}
.dotted-border-3{
    margin: 27px 0 !important;
}
.start-date{
    border-right: 1px solid color('light-tertiary');
    span{
        font-size: 14px;
        font-weight: 400;
        color: color('tertiary');
        margin-bottom: 3px;
        margin-right: 20px;
    }
    .trip-date{
        margin-right: 8px;
    //   color: color('secondary');
    }
}
.end-date{
    border-right: 0 !important;
    h5{
        margin-left: 20px;
        margin-right: 0px;
    }
}
.trip-img{
    flex-shrink: 0;
    img{
        height: 127px;
        width: 100px;
        border-radius: 12px;
        object-fit: cover;
    }
}
.trip-block{
    display: flex;
//    flex-shrink: 0;
}
.services-horizontal-card{
    margin-left: 22px;
    h6{
     margin-bottom:16px;
    }
}
.trip-time-block {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
}
.card-btn{
    padding: 6px 24px;
}
.booking-top-heading-section{
    display: flex;
    justify-content: space-between;
}
.horizontal-card-right-side{
    .archive-heading{
        display: flex;
        justify-content: end;
        margin-bottom: 12px;
       
        img{
            margin-right: 10px;
        }
    }
    .calender-small-logo{
        flex-shrink: 0;
        img{
            height: 24px;
            width: 24px;
            object-fit: cover;
            margin-right: 10px;
        }
    }
    .host-img{
         display: flex;
         justify-content: end;   
         margin-bottom: 0;
    }
    .booking-list{
        margin-bottom: 27px;
        .feature-listing-icon{
            min-width: unset;
        }
        li{
            padding: 0;
            font-size: 14px;
            img{
                margin-right: 10px;
            }
            .sub-text{
                font-size: 14px !important;
                .small-block-tex{
                    color: color('secondary');
                }
            }
        }
    }
    display: flex;
    align-items: end;
    /* justify-content: end; */
    flex-direction: column;
}
.total-card{
    display: flex;
    // align-items: end;
    justify-content: space-between;
}
.inner-calender-heading{
    display: flex;
    justify-content: space-between;
    h6{
        font-size: 20px;
        font-weight: 400;
        color:color('secondary');
        margin-bottom: 40px;
    }
    span{
        margin-left: 10px;
        display: inline-block;
    }
    .host-heading{
        font-size: 16px;
    }
}
.calender-button-block{
    gap: 10px;
    display: flex;
}
.booking-calender{
    padding-top: 40px;
    .top-heading{
        margin-bottom: 30px;
    }
    .inner-select{
        margin-bottom: 25px;
    }
}
.calender-mobile-show-btn{
    display: none;
}
// .card-divider{
//     display: none;
// }
.inner-page-small-heading{
    margin-bottom: 25px;
}
.calender-view-show{
    .Mui-selected{
        background: color('primary') !important;
        }
        .MuiPickersDay-root{
            color:color('secondary');
            height:40px;
            width: 40px;
            border-radius: 50%;
            flex-shrink: 0;
            font-size: 15px;
        }
        .MuiDayPicker-header{
         span{
            width: 40px;
            height:60px;
         }
       
        }
        .css-dplwbx-MuiPickersCalendarHeader-label{
            font-size: 20px;
            color: color('secondary');
            font-weight: 400;
         }
        //  .css-epd502{
        //     margin: 0;
        //  }
         .MuiDayPicker-weekContainer{
            justify-content: start;
         }
         .top-heading{
            margin-bottom: 0;
         }
         .MuiDateCalendar-root{
            margin: 0;
         }
         .MuiDayCalendar-slideTransition{
            min-height:270px;
         }
} 

.calender-view-show .MuiPickersDay-root.available-day::before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF4110;
    top: 30px;
}
.calender-view-show .Mui-selected::before {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 30px;
}
.host-info-listing{
    .feature-listing-icon{
        flex-shrink: 1;
        word-break:break
    }
}

  // X-Small devices (portrait phones, less than 576px) /
  @include media-breakpoint-between(xs, sm) {
    .group-img .customized-select em {
        font-size: 16px;
    }
    .calender-view-show{
        display: none;
    }
    .calender-mobile-show-btn{
        display: block;
    }
    .booking-top-heading-section {
        align-items: baseline;
    }
    .mobile-calender-bg{
        background: none;
    }
    .mobile-calender-bg .MuiPickerStaticWrapper-content{
        background: none !important;
    }
    .services-horizontal-card h6 {
        width: 350px;
    }
    .calender-mobile-show-btn button{
        color:color('secondary')
    }
    // .group-img .search-listing-space .input-text-style > div {
    //     width: 320px;
    // }
    .total-card {
        flex-wrap: wrap;
        flex-direction: column;
        padding: 15px;
        box-shadow: 0px 0px 12px rgba(9, 6, 15, 0.08) !important;
        border-radius: 15px;
    }
    .total-card .horizontal-card-right-side {
        align-items: flex-start !important;
        justify-content: flex-start;
        flex-direction: column;
    }
    .trip-block {
        margin-bottom: 25px;
    }
    .trip-img img {
        height: 130px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 20px;
    }
    .trip-time-block {
        justify-content: unset;
    }
    // .card-divider{
    //     border-bottom:1px solid color('light-tertiary');
    //     margin-bottom: 45px;
    // }
    .booking-top-heading-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .booking-calender {
        padding-top: 20px;
    }
    .booking-calender .inner-select {
        margin-bottom: 15px;
        width: 160px !important;
    }
    .trip-block {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .services-horizontal-card {
        margin-left: 0;
    }
    .services-horizontal-card h6 {
        width: 100%;
    }
    .group-img .input-style:not(.input-style.end-date) {
        border-bottom: 0px solid #EEEEEE;
    }
    .group-img{
        margin-bottom: 25px;
    }
    .date-select-btn{
        padding: 7px 15px !important;
    }
    // .card-divider {
    //     margin-bottom: 35px;
    // }
    .mobile-calendar{
        .Mui-selected{
            background: color('primary') !important;
            }
            .MuiPickersDay-root{
                color:color('secondary');
                height:40px;
                width: 40px;
                border-radius: 50%;
                flex-shrink: 0;
                font-size: 15px;
            }
            .MuiDayPicker-header{
             span{
                width: 40px;
                height:60px;
             }
           
            }
            .css-dplwbx-MuiPickersCalendarHeader-label{
                font-size: 20px;
                color: color('secondary');
             }
             .MuiDayPicker-weekContainer{
                justify-content: start;
             }
             .top-heading{
                margin-bottom: 0;
             }
    } 
    .end-date {
        margin-left: 10px;
    }
    .popup-calendar{
        width: 100% !important;
        margin: auto;
    }
    .total-card .horizontal-card-right-side .booking-list {
        margin-bottom: 10px;
    }
    .horizontal-card-right-side .booking-list li{
        justify-content: flex-start;
    }
    .mobile-booking {
        width: 100%;
        .calender-button-block{
            width: 100%;
            flex-direction: column;
            button{
                width: 100%;
            }
        }
    }
    .services-horizontal-card {
        button{
            width: 100%;
        }
    }
    .inner-page-small-heading .dotted-border-2 {
        display: none;
    }
  }
  // Small devices (landscape phones, 576px and up) /
  @include media-breakpoint-between(sm, md) {
    .group-img .customized-select em {
        font-size: 16px;
    }
    .calender-view-show{
        display: none;
    }
    .calender-mobile-show-btn{
        display: block;
    }
    .booking-top-heading-section {
        align-items: baseline;
    }
    .mobile-calender-bg{
        background: none;
    }
    .mobile-calender-bg .MuiPickerStaticWrapper-content{
        background: none !important;
    }
    .services-horizontal-card h6 {
        width: 350px;
    }
    .calender-mobile-show-btn button{
        color:color('secondary')
    }
    // .group-img .search-listing-space .input-text-style > div {
    //     width: 320px;
    // }
    .total-card {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .total-card .horizontal-card-right-side {
        align-items: start !important;
        justify-content: space-between;
        flex-direction: row;
    }
    .trip-block {
        margin-bottom: 25px;
    }
    .trip-img img {
        height: 160px;
        width: 150px;
        border-radius: 12px;
    }
    .trip-time-block {
        justify-content: unset;
    }
    // .card-divider{
    //     border-bottom:1px solid color('light-tertiary');
    //     margin-bottom: 35px;
    // }
    .booking-calender {
        padding-top: 20px;
        padding-bottom: 0 !important;
    }
   
    .date-select-btn{
        padding: 7px 15px !important;
    }
    .mobile-calendar{
        .Mui-selected{
            background: color('primary') !important;
            }
            .MuiPickersDay-root{
                color:color('secondary');
                height:40px;
                width: 40px;
                border-radius: 50%;
                flex-shrink: 0;
                font-size: 15px;
            }
            .MuiDayPicker-header{
             span{
                width: 40px;
                height:60px;
             }
           
            }
            .css-dplwbx-MuiPickersCalendarHeader-label{
                font-size: 20px;
                color: color('secondary');
             }
             .MuiDayPicker-weekContainer{
                justify-content: start;
             }
             .top-heading{
                margin-bottom: 0;
             }
    } 
    .end-date {
        margin-left: 10px;
    }
    .horizontal-card-right-side{
        .booking-list {
            margin-bottom: 20px;
            li {
                .sub-text {
                    margin-left: 0;
                }
            }
        }
    }
    .mobile-calendar{
        .mobile-calender-bg{
            height: 350px;
        }
    }
    .mobile-popup-calendar{
        width: 70% !important;
        margin: auto;
    }
    // .mobile-popup-calendar
    .booking-order .heading-right-btn {
        width: 50% !important;
        justify-content: end;
    }
    .booking-order .heading-search {
        margin-bottom: 0 !important;
    }
    .booking-order .heading-search .top-heading {
        margin-bottom: 0 !important;
    }
    .total-card .horizontal-card-right-side .booking-list {
        margin-bottom: 10px;
    }
  }
  /* // Medium devices (tablets, 768px and up) */
  @include media-breakpoint-between(md, lg) {
    .group-img .customized-select em {
        font-size: 16px;
    }
    .calender-view-show{
        display: none;
    }
    .calender-mobile-show-btn{
        display: block;
    }
    .booking-top-heading-section {
        align-items: baseline;
    }
    .mobile-calender-bg{
        background: none;
    }
    .mobile-calender-bg .MuiPickerStaticWrapper-content{
        background: none !important;
    }
    .services-horizontal-card h6 {
        // width: 350px;
    }
    .calender-mobile-show-btn button{
        color:color('secondary')
    }
    .group-img .search-listing-space .input-text-style > div {
        width: 320px;
    }
    .total-card {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .total-card .horizontal-card-right-side {
        align-items: start !important;
        justify-content: space-between;
        flex-direction: row;
    }
    .trip-block {
        margin-bottom: 25px;
    }
    .trip-img img {
        height: 140px;
        width: 170px;
        border-radius: 12px;
    }
    .trip-time-block {
        justify-content: unset;
    }
    // .card-divider{
    //     border-bottom:1px solid color('light-tertiary');
    //     margin-bottom: 25px;
    // }
    .date-select-btn{
        padding: 7px 15px !important;
    }
    .mobile-calendar{
        .Mui-selected{
            background: color('primary') !important;
            }
            .MuiPickersDay-root{
                color:color('secondary');
                height:40px;
                width: 40px;
                border-radius: 50%;
                flex-shrink: 0;
                font-size: 15px;
            }
            .MuiDayPicker-header{
             span{
                width: 40px;
                height:60px;
             }
           
            }
            .css-dplwbx-MuiPickersCalendarHeader-label{
                font-size: 20px;
                color: color('secondary');
             }
             .MuiDayPicker-weekContainer{
                justify-content: start;
             }
             .top-heading{
                margin-bottom: 0;
             }
    } 
    .end-date {
        margin-left: 10px;
    }
    .horizontal-card-right-side .booking-list li .sub-text {
        margin-left: 0;
    }
    .mobile-popup-calendar:nth-child(4) {
        box-shadow: none !important;
        margin: 0 !important;;
    }
    .total-card .horizontal-card-right-side .booking-list {
        margin-bottom: 10px;
    }
  }
  /* // Large devices (desktops, 992px and up) */
  @include media-breakpoint-between(lg, xl) {
    .total-card{
        .horizontal-card-right-side{
            width: 250px;
        }
    }
  }
  /* // Extra large devices (large desktops, 1200px and up) */
  @include media-breakpoint-between(xl, xxl) {
     
}


/* // Large devices (desktops, 992px and up) */
@media (min-width: 900px) and (max-width: 1199.98px) { 
    .calender-view-show {
        display: block !important;
    } 
    .calender-mobile-show-btn {
        display: none !important;
    } 
    .calender-view-show .MuiPickersDay-root.available-day::before {
        top: 27px;
    }
    .calender-view-show .MuiPickersDay-root {
        height: 35px;
        width: 35px;
    }
    .booking-calender .MuiDayCalendar-weekContainer{
        justify-content:start !important;
    }
    .booking-calender .MuiDayCalendar-header{
        justify-content:start !important;  
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width:578px) and (max-width: 658px) { 
    .booking-calender .select-style{
       width: 160px;
    }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width:577px) and (max-width: 637px) {

 }